import _ from 'lodash'

const state = {
    campaign: {
        type: {
            'key-distribution': {
                label: 'Organic Content',
                labelPlural: 'Organic Content',
                icon: ['fas', 'key'],
                description: `Game keys are distributed to creators. Creators are not obligated to deliver content in exchange for the key.`,
            },
            'game-sponsorship': {
                label: 'Sponsored Content',
                labelPlural: 'Sponsored Content',
                icon: ['fas', 'dollar-sign'],
                description: `Creators are targeted by our algorithm and hired to deliver content during a specific time window, and for a pre-determined amount of time.`,
            },
            'steam-broadcast': {
                label: 'Steam Broadcast',
                labelPlural: 'Steam Broadcasts',
                icon: ['fab', 'steam'],
                description: `Creators perform a marathon of live streams on your game store page, increasing conversion and offering buyer support at the point of sale.`,
            },
        },
        status: {
            kickoff: {
                label: 'Kickoff',
                description: `Meet with our team to discuss your new campaign`,
            },
            discovery: {
                label: 'Discovery',
                description: `We find the best creators to promote your game`,
            },
            execution: {
                label: 'Launch',
                description: `Your campaign begins, content is created`,
            },
            reporting: {
                label: 'Reporting',
                description: `Performance is analyzed and a report is generated for you`,
            },
        },
        lifecycle_stage: {
            closed_alpha: { label: 'Closed Alpha', value: 'Closed Alpha', order: 1 },
            closed_beta: { label: 'Closed Beta', value: 'Closed Beta', order: 2 },
            open_alpha: { label: 'Open Alpha', value: 'Open Alpha', order: 3 },
            open_beta: { label: 'Open Beta', value: 'Open Beta', order: 4 },
            release: { label: 'Release', value: 'Release', order: 5 },
            patch: { label: 'Patch', value: 'Patch', order: 6 },
            dlc: { label: 'DLC', value: 'DLC', order: 7 },
            platform_release: { label: 'Platform Release', value: 'Platform Release', order: 8 },
            early_access: { label: 'Early Access', value: 'Early Access', order: 9 },
        },

        stages: {
            prospect: {
                label: 'Prospecting',
                status: {
                    removed: {
                        label: 'Removed',
                        index: 'X',
                    },
                    'not-started': {
                        label: 'Not started',
                        index: 0,
                    },
                    started: {
                        label: 'Added',
                        index: 1,
                    },
                    approved: {
                        label: 'Pre-Approved',
                        index: 2,
                    },
                    'inquiry-sent': {
                        label: 'Inquiry sent',
                        index: 3,
                    },
                    complete: {
                        label: 'Complete',
                        index: 4,
                    },
                },
                bgClass: 'bg-sky-500',
                index: 0,
            },
            pricing: {
                label: 'Pricing',
                status: {
                    removed: {
                        label: 'Removed',
                        index: 'X',
                    },
                    'not-started': {
                        label: 'Not started',
                        index: 0,
                    },
                    started: {
                        label: 'Inquiry Sent',
                        index: 1,
                    },
                    'price-received': {
                        label: 'Price received',
                        index: 2,
                    },
                    'counter-offer': {
                        label: 'Counter offer',
                        index: 3,
                    },
                    'creator-counter-offer': {
                        label: 'Creator counter offer',
                        index: 4,
                    },
                    'price-accepted': {
                        label: 'Price accepted',
                        index: 5,
                    },
                    complete: {
                        label: 'Complete',
                        index: 6,
                    },
                },
                bgClass: 'bg-emerald-500',
                index: 1,
            },
            contracting: {
                label: 'Contracting',
                status: {
                    removed: {
                        label: 'Removed',
                        index: 'X',
                    },
                    'not-started': {
                        label: 'Not started',
                        index: 0,
                    },
                    started: {
                        label: 'Started',
                        index: 1,
                    },
                    'contract-generated': {
                        label: 'Contract generated',
                        index: 2,
                    },
                    'contract-sent': {
                        label: 'Contract sent',
                        index: 3,
                    },
                    'changes-requested': {
                        label: 'Changes request',
                        index: 4,
                    },
                    'changes-completed': {
                        label: 'Changes completed',
                        index: 5,
                    },
                    'contract-signed': {
                        label: 'Contract signed',
                        index: 6,
                    },
                    'contract-countersigned': {
                        label: 'Contract countersigned',
                        index: 7,
                    },
                    complete: {
                        label: 'Complete',
                        index: 8,
                    },
                },
                bgClass: 'bg-indigo-500',
                index: 2,
            },
            preparation: {
                label: 'Preparation',
                status: {
                    removed: {
                        label: 'Removed',
                        index: 'X',
                    },
                    'not-started': {
                        label: 'Not started',
                        index: 0,
                    },
                    started: {
                        label: 'Started',
                        index: 1,
                    },
                    'assets-sent': {
                        label: 'Assets sent',
                        index: 2,
                    },
                    'content-approved': {
                        label: 'Content approved',
                        index: 3,
                    },
                    complete: {
                        label: 'Complete',
                        index: 4,
                    },
                },
                bgClass: 'bg-rose-400',
                index: 3,
            },
            delivery: {
                label: 'Delivery',
                status: {
                    removed: {
                        label: 'Removed',
                        index: 'X',
                    },
                    'not-started': {
                        label: 'Not started',
                        index: 0,
                    },
                    started: {
                        label: 'Awaiting Delivery',
                        index: 1,
                    },
                    'content-approved': {
                        label: 'Content approved',
                        index: 2,
                    },
                    'content-associated': {
                        label: 'Content associated',
                        index: 3,
                    },
                    paid: {
                        label: 'Paid',
                        index: 4,
                    },
                    complete: {
                        label: 'Complete',
                        index: 5,
                    },
                },
                bgClass: 'bg-amber-500',
                index: 4,
            },
        },
        schedule_status: {
            drafting: { label: 'Drafting Schedule', value: 'draft', order: 1 },
            locked_in: { label: 'Creators Locked In', value: 'locked', order: 2 },
        },
        applications_state: {
            open: { label: 'Accepting Applications', value: 'open', order: 1 },
            locked: { label: 'Not Accepting Applications', value: 'locked', order: 2 },
        },
        bid: {
            status: {
                created: { label: 'Created', value: 'created', order: 1 },
                sent: { label: 'Pending', value: 'sent', order: 2 },
                declined: { label: 'Declined', value: 'declined', order: 3 },
                accepted: { label: 'Accepted', value: 'accepted', order: 5 },
                considered: { label: 'Considered', value: 'considered', order: 4 },
            },
            sort_options: {
                name: { label: 'Name' },
                date: { label: 'Newest' },
                price: { label: 'Cost' },
                value: { label: 'Value' },
            },
        },
    },

    finance: {
        payment: {
            status: {
                created: { label: 'Created', value: 'created', order: 1 },
                verified: { label: 'Verified', value: 'verified', order: 1 },
                approved: { label: 'Approved', value: 'approved', order: 1 },
                pending: { label: 'Pending', value: 'pending', order: 1 },
                paid: { label: 'Paid', value: 'paid', order: 1 },
                error: { label: 'Error', value: 'error', order: 1 },
                canceled: { label: 'Canceled', value: 'canceled', order: 1 },
            },
        },
        payee: {
            assignment: {
                assigned: { title: 'Assigned' },
                unassigned: { title: 'No' },
            },
            availability: {
                none: { title: 'None' },
                incomplete: { title: 'Available - incomplete' },
                available: { title: 'Available' },
            },
            status: {
                incomplete: { title: 'Incomplete' },
                active: { title: 'Active' },
                removed: { title: 'Removed' },
            },
        },
        rate_types: {
            'twitch-cpavph': {
                title: 'Hourly cost per average viewer',
                title_short: 'CPACV (1 hour)',
                order: 1,
            },
            'twitch-cpavph2': {
                title: 'Cost per average viewer, 2 hours',
                title_short: 'CPACV (2 hours)',
                order: 2,
            },
            'twitch-cpavph4': {
                title: 'Cost per average viewer, 4 hours',
                title_short: 'CPACV (4 hours)',
                order: 3,
            },
            'youtube-cpm': {
                title: 'CPM',
                title_short: 'CPM',
                order: 1,
            },
        },
    },

    feed: {
        model: {
            participant: {
                created: {
                    icon: ['fas', 'user-plus'],
                },
                approved: {
                    icon: ['fas', 'user-check'],
                },
            },
            campaign: {
                created: {
                    icon: ['fas', 'plus'],
                },
            },
            supercampaign: {
                created: {
                    icon: ['fas', 'plus'],
                },
            },
        },
    },

    participant: {
        checklist: {
            groups: {
                prospect: {
                    title: 'Prospect',
                    icon: ['fas', 'search'],
                    description: 'Creator identified as a good match for campaign. Not yet contacted.',
                },
                negotiation: {
                    title: 'Negotiation',
                    icon: ['fas', 'dollar-sign'],
                    description: 'Communication established, contract details are being worked out.',
                },
                onboarded: {
                    title: 'Onboarded',
                    icon: ['fas', 'sign-in-alt'],
                    description: 'Creator is registered on our dashboard, deliverables can be communicated.',
                },
            },
            fields: {
                contacted: 'Contacted',
                sent_for_client_approval: 'Sent for client approval',
                received_client_approval: 'Client approval received',
                reply_received: 'Reply received',
                rate_established: 'Rate established',
                deliverables_confirmed: 'Deliverables confirmed',
                contract_signed: 'Contract signed',
                tasks_created: 'Tasks created',
            },
        },
        status: {
            all: {
                title: 'All',
                icon: ['fas', 'users'],
            },
            registered: {
                title: 'Onboarded',
                icon: ['fas', 'sign-in-alt'],
            },
            added: {
                title: 'Prospect',
                icon: ['fas', 'search'],
            },
            prospect: {
                title: 'Prospect',
                icon: ['fas', 'search'],
            },
            contacted: {
                title: 'Contacted',
                icon: ['fas', 'comments'],
            },
            invited: {
                title: 'Invited',
                icon: ['fas', 'envelope-open'],
            },
        },
        triage_status: {
            all: {
                label: 'All',
            },
            approved: {
                label: 'Approved',
            },
            denied: {
                label: 'Denied',
            },
            pending: {
                label: 'Pending',
            },
        },
    },

    task: {
        type: {
            'custom-text': {
                label: 'Custom instructions',
            },
            'retrieve-key': {
                label: 'Retrieve key from vault',
            },
            'twitch-stream': {
                label: 'Sponsored Twitch stream',
            },
            'youtube-video': {
                label: 'Sponsored YouTube video',
            },
            'twitch-panel': {
                label: 'Setup tracking panel',
            },
            'tiktok-video': {
                label: 'Sponsored TikTok Video',
            },
            'twitter-post': {
                label: 'Sponsored Twitter Post',
            },
            'instagram-post': {
                label: 'Sponsored Instagram Post',
            },
        },

        title: {
            'custom-text': 'Custom instructions',
            'retrieve-key': 'Retrieve key from vault',
            'twitch-stream': 'Sponsored Twitch stream',
            'youtube-video': 'Sponsored YouTube video',
            'twitch-panel': 'Setup tracking',
        },
        icon: {
            'custom-text': ['fas', 'question'],
            'retrieve-key': ['fas', 'key'],
            'twitch-stream': ['fas', 'broadcast-tower'],
            'twitch-panel': ['fas', 'chart-line'],
        },

        'twitch-stream': {
            title: 'Sponsored Twitch Stream',
            icon: ['fab', 'twitch'],
            textColor: 'text-indigo-600',
            bgColor: 'bg-indigo-500',
            description:
                'You stream to your channel on Twitch with your category set to the game for a set period of time.',
        },

        'youtube-video': {
            title: 'Sponsored YouTube video',
            icon: ['fab', 'youtube'],
            textColor: 'text-youtube',
            bgColor: 'bg-youtube',
            description: 'You create a dedicated video on your channel showcasing the product/service.',
        },

        'retrieve-key': {
            title: 'Retrieve Key',
            icon: ['fas', 'key'],
            textColor: 'text-slate-600',
            bgColor: 'text-slate-200',
            description: 'Grab a key from our platform so you can play the game.',
        },

        'tiktok-video': {
            title: 'Sponsored TikTok video',
            icon: ['fab', 'tiktok'],
            textColor: 'text-tiktok',
            bgColor: 'bg-tiktok',
            description: 'You create a dedicated video on your profile showcasing the product/service.',
        },

        'twitter-post': {
            title: 'Sponsored Twitter video',
            icon: ['fab', 'twitter'],
            textColor: 'text-twitter',
            bgColor: 'bg-twitter',
            description: 'You create a dedicated post on your profile showcasing the product/service.',
        },

        'instagram-post': {
            title: 'Sponsored Instagram video',
            icon: ['fab', 'instagram'],
            textColor: 'text-instagram',
            bgColor: 'bg-instagram',
            description: 'You create a dedicated post on your profile showcasing the product/service.',
        },

        operators: {
            eq: {
                label: 'Equals',
            },
            gt: {
                label: 'Greater than',
            },
            lt: {
                label: 'Less than',
            },
            btwn: {
                label: 'Between (Inclusive)',
            },
        },
    },

    list: {
        modal: {
            type: {
                update: {
                    title: 'Update List',
                    actionLabel: 'Update',
                },
                delete: {
                    title: 'Delete List',
                    actionLabel: 'Delete',
                },
                create: {
                    title: 'Create List',
                    actionLabel: 'Create',
                },
                remove: {
                    title: 'Remove Creators',
                    actionLabel: 'Remove',
                },
                access: {
                    title: 'Share List',
                },
            },
        },
    },

    organization: {
        role: {
            superadmin: {
                label: 'Owner',
                labelPlural: 'Owners',
                description: `Owners are granted all permissions and cannot be removed from the organization.`,
            },
            admin: {
                label: 'Manager',
                labelPlural: 'Managers',
                description: `Managers can invite and manage other users in the organization.`,
            },
            user: {
                label: 'User',
                labelPlural: 'Users',
                description: `Users have standard access to the platform according to the tier you are subscribed to.`,
            },
        },
        type: {
            publisher: {
                label: 'Publisher',
            },
            'marketing-agency': {
                label: 'Marketing Agency',
            },
            'talent-agency': {
                label: 'Talent Agency',
            },
        },
    },

    platforms: {
        toAbbreviations: {
            twitch: 'tw',
            youtube: 'yt',
            twitter: 'tt',
            facebook: 'fb',
            instagram: 'ig',
        },
        fromAbbreviations: {
            tw: 'twitch',
            yt: 'youtube',
            tt: 'twitter',
            fb: 'facebook',
            ig: 'instagram',
        },
        toSuperviewKeys: {
            twitter: 'twitter_com',
            instagram: 'instagram_com',
            tiktok: 'tiktok_com',
            youtube: 'youtube_com',
            twitch: 'twitch_tv',
        },
        fromSuperviewKeys: {
            twitter_com: 'twitter',
            instagram_com: 'instagram',
            tiktok_com: 'tiktok',
            youtube_com: 'youtube',
            twitch_tv: 'twitch',
        },
    },

    superview: {
        metricWindow: {
            day_30: '30 day',
            day_90: '90 day',
            day_180: '180 day',
            day_365: '365 day',
            day_1095: '3 year',
        },

        class: {
            variety: {
                label: 'Variety Content',
                description: 'A creator that splits their airtime streaming across multiple games.',
            },
            flexible: {
                label: 'Flexible Content',
                description:
                    'A creator that might focus a few games, but streams outside of that core group frequently.',
            },
            strict: {
                label: 'Focused Content',
                description: 'A creator that spends a majority of their airtime streaming one game.',
            },
        },

        safetyRating: {
            G: {
                label: 'General Audiences',
            },
            PG: {
                label: 'Parental Guidance Suggested',
            },
            'PG-13': {
                label: 'Parental Guidance Cautioned',
            },
            R: {
                label: 'Restricted',
            },
        },

        ageRange: {
            Under18: 'under 18',
            _18To24: '18-24',
            _25To34: '25-34',
            _35To44: '35-44',
            _45To54: '45-54',
            _55To64: '55-64',
            _65Plus: '65+',
        },

        archetypes: {
            'The Champion': {
                icon: ['fas', 'trophy'],
            },
            'The Analyst': {
                icon: ['fas', 'search'],
            },
            'The Entertainer': {
                icon: ['fas', 'laugh'],
            },
            'The Storyteller': {
                icon: ['fas', 'book-open'],
            },
            'The Educator': {
                icon: ['fas', 'chalkboard-teacher'],
            },
            'The Completionist': {
                icon: ['fas', 'medal'],
            },
            'The Explorer': {
                icon: ['fas', 'compass'],
            },
            'The Modder': {
                icon: ['fas', 'cogs'],
            },
            'The Speedrunner': {
                icon: ['fas', 'stopwatch'],
            },
            'The Community Leader': {
                icon: ['fas', 'users'],
            },
            'The Historian': {
                icon: ['fas', 'scroll'],
            },
            'The Social Commentator': {
                icon: ['fas', 'comment-dots'],
            },
        },

        personalityTrait: {
            Charismatic: {
                icon: ['fas', 'grin-stars'],
            },
            Analytical: {
                icon: ['fas', 'chart-line'],
            },
            Humorous: {
                icon: ['fas', 'laugh'],
            },
            Provocative: {
                icon: ['fas', 'fire'],
            },
            Energetic: {
                icon: ['fas', 'bolt'],
            },
            Calm: {
                icon: ['fas', 'spa'],
            },
            Articulate: {
                icon: ['fas', 'comments'],
            },
            Relatable: {
                icon: ['fas', 'handshake'],
            },
            Optimistic: {
                icon: ['fas', 'sun'],
            },
            Pessimistic: {
                icon: ['fas', 'cloud'],
            },
            Eccentric: {
                icon: ['fas', 'hat-wizard'],
            },
            Cynical: {
                icon: ['fas', 'meh'],
            },
            Inquisitive: {
                icon: ['fas', 'search'],
            },
            Dramatic: {
                icon: ['fas', 'mask'],
            },
            Passionate: {
                icon: ['fas', 'heart'],
            },
            Sincere: {
                icon: ['fas', 'hand-heart'],
            },
            Sarcastic: {
                icon: ['fas', 'smile-wink'],
            },
            Intellectual: {
                icon: ['fas', 'book'],
            },
            Introverted: {
                icon: ['fas', 'user-secret'],
            },
            Extroverted: {
                icon: ['fas', 'users'],
            },
            Creative: {
                icon: ['fas', 'paint-brush'],
            },
            Spontaneous: {
                icon: ['fas', 'dice'],
            },
            Confident: {
                icon: ['fas', 'award'],
            },
            Reserved: {
                icon: ['fas', 'user-lock'],
            },
            Emotional: {
                icon: ['fas', 'angry'],
            },
            Objective: {
                icon: ['fas', 'balance-scale'],
            },
            Insightful: {
                icon: ['fas', 'lightbulb'],
            },
            Irreverent: {
                icon: ['fas', 'grin-tongue'],
            },
            Empathetic: {
                icon: ['fas', 'hands-helping'],
            },
            Competitive: {
                icon: ['fas', 'trophy'],
            },
            Professional: {
                icon: ['fas', 'briefcase'],
            },
            Formal: {
                icon: ['fas', 'user-tie'],
            },
            Casual: {
                icon: ['fas', 'tshirt'],
            },
            Satirical: {
                icon: ['fas', 'journal-whills'],
            },
            Educational: {
                icon: ['fas', 'graduation-cap'],
            },
            Wholesome: {
                icon: ['fas', 'leaf'],
            },
            Rebellious: {
                icon: ['fas', 'fist-raised'],
            },
            Altruistic: {
                icon: ['fas', 'gift'],
            },
            Skeptical: {
                icon: ['fas', 'question'],
            },
            Nostalgic: {
                icon: ['fas', 'clock'],
            },
            Witty: {
                icon: ['fas', 'hat-cowboy'],
            },
            Sentimental: {
                icon: ['fas', 'memory'],
            },
            Gritty: {
                icon: ['fas', 'shield-alt'],
            },
            Focused: {
                icon: ['fas', 'eye'],
            },
            Thoughtful: {
                icon: ['fas', 'brain'],
            },
            Driven: {
                icon: ['fas', 'car'],
            },
            Captivating: {
                icon: ['fas', 'magic'],
            },
            Melodramatic: {
                icon: ['fas', 'theater-masks'],
            },
            'Self-deprecating': {
                icon: ['fas', 'frown'],
            },
        },

        contentStyles: {
            "Let's Play": { icon: ['fas', 'gamepad'] },
            Speedrunning: { icon: ['fas', 'stopwatch'] },
            'Walkthroughs & Tutorials': { icon: ['fas', 'chalkboard-teacher'] },
            'Review and Critique': { icon: ['fas', 'star-half-alt'] },
            'Lore Exploration': { icon: ['fas', 'book-open'] },
            'Challenge Runs': { icon: ['fas', 'medal'] },
            'Meta Analysis': { icon: ['fas', 'chart-bar'] },
            'Easter Eggs & Secrets': { icon: ['fas', 'egg'] },
            Machinima: { icon: ['fas', 'film'] },
            Theorycrafting: { icon: ['fas', 'cogs'] },
            'Patch Notes Rundown': { icon: ['fas', 'clipboard-list'] },
            'News & Leaks': { icon: ['fas', 'bullhorn'] },
            'Collaborative Gameplay': { icon: ['fas', 'users'] },
            'Event Coverage': { icon: ['fas', 'calendar-alt'] },
            'Reaction & Hype': { icon: ['fas', 'fire-alt'] },
            'Debunking & Mythbusting': { icon: ['fas', 'skull-crossbones'] },
            'Unboxing & Merchandise': { icon: ['fas', 'box-open'] },
            'Tech Analysis': { icon: ['fas', 'microscope'] },
            'IRL Crossover': { icon: ['fas', 'street-view'] },
            'Archival & Retro': { icon: ['fas', 'archive'] },
            'Social Commentary': { icon: ['fas', 'comment-alt'] },
            'Fan Theories': { icon: ['fas', 'lightbulb'] },
            'Podcast Style': { icon: ['fas', 'microphone-alt'] },
            'Montages & Highlights': { icon: ['fas', 'video'] },
            'Nostalgia Trips': { icon: ['fas', 'child'] },
            'Educational Analysis': { icon: ['fas', 'graduation-cap'] },
            'Family-Friendly Content': { icon: ['fas', 'home'] },
            'Hardware and Setup Tours': { icon: ['fas', 'tools'] },
            'Character Studies': { icon: ['fas', 'user-alt'] },
            'Role-Playing': { icon: ['fas', 'theater-masks'] },
            'Accessibility Reviews': { icon: ['fas', 'wheelchair'] },
            'Real-Time Strategy (RTS) Guides': { icon: ['fas', 'chess'] },
            'Comedy and Parody': { icon: ['fas', 'laugh-beam'] },
            'Audience Interaction': { icon: ['fas', 'hand-pointer'] },
            'Behind-the-Scenes': { icon: ['fas', 'camera-retro'] },
            'Mobile Gaming': { icon: ['fas', 'mobile-alt'] },
            'Comparative Analysis': { icon: ['fas', 'balance-scale'] },
            'Livestream Highlights': { icon: ['fas', 'stream'] },
            'Game Music Covers': { icon: ['fas', 'music'] },
            'Narrative Breakdown': { icon: ['fas', 'book'] },
            'VR/AR Gameplay': { icon: ['fas', 'vr-cardboard'] },
            'Game Development': { icon: ['fas', 'laptop-code'] },
            'Puzzle Solutions': { icon: ['fas', 'puzzle-piece'] },
            'Farming & Grinding Guides': { icon: ['fas', 'tractor'] },
            'AI & Automation': { icon: ['fas', 'robot'] },
            'Dating Sim Walkthroughs': { icon: ['fas', 'heart'] },
            'Cross-Platform Analysis': { icon: ['fas', 'exchange-alt'] },
            'In-Character Vlogging': { icon: ['fas', 'video-slash'] },
            'Time-Lapsed Gameplay': { icon: ['fas', 'clock'] },
            'Game Engine Explorations': { icon: ['fas', 'engine-warning'] },
            'Cinematic Narrations': { icon: ['fas', 'film-alt'] },
            'Subculture Deep Dives': { icon: ['fas', 'glasses'] },
            'Non-Gaming Activities': { icon: ['fas', 'basketball-ball'] },
            'Localized Content': { icon: ['fas', 'language'] },
            "Interactive Let's Play": { icon: ['fas', 'mouse-pointer'] },
            'Cosplay Showcases': { icon: ['fas', 'mask'] },
            'Language-Specific Content': { icon: ['fas', 'comments'] },
            'Memes and Humor': { icon: ['fas', 'smile-beam'] },
        },

        type: {
            individual: {
                label: 'Individual',
            },
            publisher_developer: {
                label: 'Publisher/Developer',
            },
            team: {
                label: 'Team',
            },
            esports: {
                label: 'Esports',
            },
            brand: {
                label: 'Brand',
            },
            show: {
                label: 'Show',
            },
            traditional_sports: {
                label: 'Traditional Sports',
            },
            media: {
                label: 'Media',
            },
        },
    },

    keys: {
        modal: {
            type: {
                'key-group': {
                    title: 'Add Game Key Group',
                    actionLabel: 'Add key group',
                },
                'add-keys': {
                    title: 'Add Game Keys',
                    actionLabel: 'Add game keys',
                },
                'remove-keys': {
                    title: 'Remove Game Keys',
                    actionLabel: 'Remove',
                },
                'remove-group': {
                    title: 'Remove Game Key Group',
                    actionLabel: 'Remove',
                },
                'retrieve-keys': {
                    title: 'Retrieve Game Keys',
                    actionLabel: 'Retrieve',
                },
                'view-keys': {
                    title: 'Game Key Code',
                },
            },
        },
    },

    codes: {
        conjunction: {
            $or: {
                label: 'Any',
            },
            $and: {
                label: 'All',
            },
            $nor: {
                label: 'All',
            },
            $notall: {
                label: 'Any',
            },
            $nin: {
                label: 'All',
            },
        },
        channelType: {
            individual: 'Individual',
            brand: 'Brand',
            publisher_developer: 'Publisher/Developer',
            team: 'Team',
            esports: 'Esports',
            show: 'Show',
            traditional_sports: 'Traditional Sports',
        },
        language: {
            ab: 'Abkhazian',
            aa: 'Afar',
            af: 'Afrikaans',
            ak: 'Akan',
            sq: 'Albanian',
            am: 'Amharic',
            ar: 'Arabic',
            an: 'Aragonese',
            hy: 'Armenian',
            as: 'Assamese',
            av: 'Avaric',
            ae: 'Avestan',
            ay: 'Aymara',
            az: 'Azerbaijani',
            bm: 'Bambara',
            ba: 'Bashkir',
            eu: 'Basque',
            be: 'Belarusian',
            bn: 'Bengali',
            bh: 'Bihari languages',
            bi: 'Bislama',
            nb: 'Bokmål, Norwegian; Norwegian Bokmål',
            bs: 'Bosnian',
            br: 'Breton',
            bg: 'Bulgarian',
            my: 'Burmese',
            ca: 'Catalan; Valencian',
            km: 'Central Khmer',
            ch: 'Chamorro',
            ce: 'Chechen',
            ny: 'Chichewa; Chewa; Nyanja',
            zh: 'Chinese',
            cu: 'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
            cv: 'Chuvash',
            kw: 'Cornish',
            co: 'Corsican',
            cr: 'Cree',
            hr: 'Croatian',
            cs: 'Czech',
            da: 'Danish',
            dv: 'Divehi; Dhivehi; Maldivian',
            nl: 'Dutch; Flemish',
            dz: 'Dzongkha',
            en: 'English',
            eo: 'Esperanto',
            et: 'Estonian',
            ee: 'Ewe',
            fo: 'Faroese',
            fj: 'Fijian',
            fi: 'Finnish',
            fr: 'French',
            ff: 'Fulah',
            gd: 'Gaelic; Scottish Gaelic',
            gl: 'Galician',
            lg: 'Ganda',
            ka: 'Georgian',
            de: 'German',
            el: 'Greek',
            gn: 'Guarani',
            gu: 'Gujarati',
            ht: 'Haitian; Haitian Creole',
            ha: 'Hausa',
            he: 'Hebrew',
            hz: 'Herero',
            hi: 'Hindi',
            ho: 'Hiri Motu',
            hu: 'Hungarian',
            is: 'Icelandic',
            io: 'Ido',
            ig: 'Igbo',
            id: 'Indonesian',
            ia: 'Interlingua (International Auxiliary Language Association)',
            ie: 'Interlingue; Occidental',
            iu: 'Inuktitut',
            ik: 'Inupiaq',
            ga: 'Irish',
            it: 'Italian',
            ja: 'Japanese',
            jv: 'Javanese',
            kl: 'Kalaallisut; Greenlandic',
            kn: 'Kannada',
            kr: 'Kanuri',
            ks: 'Kashmiri',
            kk: 'Kazakh',
            ki: 'Kikuyu; Gikuyu',
            rw: 'Kinyarwanda',
            ky: 'Kirghiz; Kyrgyz',
            kv: 'Komi',
            kg: 'Kongo',
            ko: 'Korean',
            kj: 'Kuanyama; Kwanyama',
            ku: 'Kurdish',
            lo: 'Lao',
            la: 'Latin',
            lv: 'Latvian',
            li: 'Limburgan; Limburger; Limburgish',
            ln: 'Lingala',
            lt: 'Lithuanian',
            lu: 'Luba-Katanga',
            lb: 'Luxembourgish; Letzeburgesch',
            mk: 'Macedonian',
            mg: 'Malagasy',
            ms: 'Malay',
            ml: 'Malayalam',
            mt: 'Maltese',
            gv: 'Manx',
            mi: 'Maori',
            mr: 'Marathi',
            mh: 'Marshallese',
            mn: 'Mongolian',
            na: 'Nauru',
            nv: 'Navajo; Navaho',
            nd: 'Ndebele, North; North Ndebele',
            nr: 'Ndebele, South; South Ndebele',
            ng: 'Ndonga',
            ne: 'Nepali',
            se: 'Northern Sami',
            no: 'Norwegian',
            nn: 'Norwegian Nynorsk; Nynorsk, Norwegian',
            oc: 'Occitan (post 1500)',
            oj: 'Ojibwa',
            or: 'Oriya',
            om: 'Oromo',
            os: 'Ossetian; Ossetic',
            pi: 'Pali',
            pa: 'Panjabi; Punjabi',
            fa: 'Persian',
            pl: 'Polish',
            pt: 'Portuguese',
            ps: 'Pushto; Pashto',
            qu: 'Quechua',
            ro: 'Romanian; Moldavian; Moldovan',
            rm: 'Romansh',
            rn: 'Rundi',
            ru: 'Russian',
            sm: 'Samoan',
            sg: 'Sango',
            sa: 'Sanskrit',
            sc: 'Sardinian',
            sr: 'Serbian',
            sn: 'Shona',
            ii: 'Sichuan Yi; Nuosu',
            sd: 'Sindhi',
            si: 'Sinhala; Sinhalese',
            sk: 'Slovak',
            sl: 'Slovenian',
            so: 'Somali',
            st: 'Sotho, Southern',
            es: 'Spanish; Castilian',
            su: 'Sundanese',
            sw: 'Swahili',
            ss: 'Swati',
            sv: 'Swedish',
            tl: 'Tagalog',
            ty: 'Tahitian',
            tg: 'Tajik',
            ta: 'Tamil',
            tt: 'Tatar',
            te: 'Telugu',
            th: 'Thai',
            bo: 'Tibetan',
            ti: 'Tigrinya',
            to: 'Tonga (Tonga Islands)',
            ts: 'Tsonga',
            tn: 'Tswana',
            tr: 'Turkish',
            tk: 'Turkmen',
            tw: 'Twi',
            ug: 'Uighur; Uyghur',
            uk: 'Ukrainian',
            ur: 'Urdu',
            uz: 'Uzbek',
            ve: 'Venda',
            vi: 'Vietnamese',
            vo: 'Volapük',
            wa: 'Walloon',
            cy: 'Welsh',
            fy: 'Western Frisian',
            wo: 'Wolof',
            xh: 'Xhosa',
            yi: 'Yiddish',
            yo: 'Yoruba',
            za: 'Zhuang; Chuang',
            zu: 'Zulu',
        },
        country: {
            ad: 'Andorra',
            ae: 'United Arab Emirates',
            af: 'Afghanistan',
            ag: 'Antigua and Barbuda',
            ai: 'Anguilla',
            al: 'Albania',
            am: 'Armenia',
            an: 'Netherlands Antilles',
            ao: 'Angola',
            aq: 'Antarctica',
            ar: 'Argentina',
            as: 'American Samoa',
            at: 'Austria',
            au: 'Australia',
            aw: 'Aruba',
            ax: 'Åland Islands',
            az: 'Azerbaijan',
            ba: 'Bosnia and Herzegovina',
            bb: 'Barbados',
            bd: 'Bangladesh',
            be: 'Belgium',
            bf: 'Burkina Faso',
            bg: 'Bulgaria',
            bh: 'Bahrain',
            bi: 'Burundi',
            bj: 'Benin',
            bl: 'Saint Barthélemy',
            bm: 'Bermuda',
            bn: 'Brunei Darussalam',
            bo: 'Bolivia, Plurinational State of',
            br: 'Brazil',
            bs: 'Bahamas',
            bt: 'Bhutan',
            bv: 'Bouvet Island',
            bw: 'Botswana',
            by: 'Belarus',
            bz: 'Belize',
            ca: 'Canada',
            cc: 'Cocos (Keeling) Islands',
            cd: 'Congo, the Democratic Republic of the',
            cf: 'Central African Republic',
            cg: 'Congo',
            ch: 'Switzerland',
            ci: "Côte d'Ivoire",
            ck: 'Cook Islands',
            cl: 'Chile',
            cm: 'Cameroon',
            cn: 'China',
            co: 'Colombia',
            cr: 'Costa Rica',
            cu: 'Cuba',
            cv: 'Cape Verde',
            cx: 'Christmas Island',
            cy: 'Cyprus',
            cz: 'Czech Republic',
            de: 'Germany',
            dj: 'Djibouti',
            dk: 'Denmark',
            dm: 'Dominica',
            do: 'Dominican Republic',
            dz: 'Algeria',
            ec: 'Ecuador',
            ee: 'Estonia',
            eg: 'Egypt',
            eh: 'Western Sahara',
            er: 'Eritrea',
            es: 'Spain',
            et: 'Ethiopia',
            fi: 'Finland',
            fj: 'Fiji',
            fk: 'Falkland Islands (Malvinas)',
            fm: 'Micronesia, Federated States of',
            fo: 'Faroe Islands',
            fr: 'France',
            ga: 'Gabon',
            gb: 'United Kingdom',
            gd: 'Grenada',
            ge: 'Georgia',
            gf: 'French Guiana',
            gg: 'Guernsey',
            gh: 'Ghana',
            gi: 'Gibraltar',
            gl: 'Greenland',
            gm: 'Gambia',
            gn: 'Guinea',
            gp: 'Guadeloupe',
            gq: 'Equatorial Guinea',
            gr: 'Greece',
            gs: 'South Georgia and the South Sandwich Islands',
            gt: 'Guatemala',
            gu: 'Guam',
            gw: 'Guinea-Bissau',
            gy: 'Guyana',
            hk: 'Hong Kong',
            hm: 'Heard Island and McDonald Islands',
            hn: 'Honduras',
            hr: 'Croatia',
            ht: 'Haiti',
            hu: 'Hungary',
            id: 'Indonesia',
            ie: 'Ireland',
            il: 'Israel',
            im: 'Isle of Man',
            in: 'India',
            io: 'British Indian Ocean Territory',
            iq: 'Iraq',
            ir: 'Iran, Islamic Republic of',
            is: 'Iceland',
            it: 'Italy',
            je: 'Jersey',
            jm: 'Jamaica',
            jo: 'Jordan',
            jp: 'Japan',
            ke: 'Kenya',
            kg: 'Kyrgyzstan',
            kh: 'Cambodia',
            ki: 'Kiribati',
            km: 'Comoros',
            kn: 'Saint Kitts and Nevis',
            kp: "Korea, Democratic People's Republic of",
            kr: 'Korea, Republic of',
            kw: 'Kuwait',
            ky: 'Cayman Islands',
            kz: 'Kazakhstan',
            la: "Lao People's Democratic Republic",
            lb: 'Lebanon',
            lc: 'Saint Lucia',
            li: 'Liechtenstein',
            lk: 'Sri Lanka',
            lr: 'Liberia',
            ls: 'Lesotho',
            lt: 'Lithuania',
            lu: 'Luxembourg',
            lv: 'Latvia',
            ly: 'Libyan Arab Jamahiriya',
            ma: 'Morocco',
            mc: 'Monaco',
            md: 'Moldova, Republic of',
            me: 'Montenegro',
            mf: 'Saint Martin (French part)',
            mg: 'Madagascar',
            mh: 'Marshall Islands',
            mk: 'Macedonia, the former Yugoslav Republic of',
            ml: 'Mali',
            mm: 'Myanmar',
            mn: 'Mongolia',
            mo: 'Macao',
            mp: 'Northern Mariana Islands',
            mq: 'Martinique',
            mr: 'Mauritania',
            ms: 'Montserrat',
            mt: 'Malta',
            mu: 'Mauritius',
            mv: 'Maldives',
            mw: 'Malawi',
            mx: 'Mexico',
            my: 'Malaysia',
            mz: 'Mozambique',
            na: 'Namibia',
            nc: 'New Caledonia',
            ne: 'Niger',
            nf: 'Norfolk Island',
            ng: 'Nigeria',
            ni: 'Nicaragua',
            nl: 'Netherlands',
            no: 'Norway',
            np: 'Nepal',
            nr: 'Nauru',
            nu: 'Niue',
            nz: 'New Zealand',
            om: 'Oman',
            pa: 'Panama',
            pe: 'Peru',
            pf: 'French Polynesia',
            pg: 'Papua New Guinea',
            ph: 'Philippines',
            pk: 'Pakistan',
            pl: 'Poland',
            pm: 'Saint Pierre and Miquelon',
            pn: 'Pitcairn',
            pr: 'Puerto Rico',
            ps: 'Palestinian Territory, Occupied',
            pt: 'Portugal',
            pw: 'Palau',
            py: 'Paraguay',
            qa: 'Qatar',
            re: 'Réunion',
            ro: 'Romania',
            rs: 'Serbia',
            ru: 'Russian Federation',
            rw: 'Rwanda',
            sa: 'Saudi Arabia',
            sb: 'Solomon Islands',
            sc: 'Seychelles',
            sd: 'Sudan',
            se: 'Sweden',
            sg: 'Singapore',
            sh: 'Saint Helena, Ascension and Tristan da Cunha',
            si: 'Slovenia',
            sj: 'Svalbard and Jan Mayen',
            sk: 'Slovakia',
            sl: 'Sierra Leone',
            sm: 'San Marino',
            sn: 'Senegal',
            so: 'Somalia',
            sr: 'Suriname',
            st: 'Sao Tome and Principe',
            sv: 'El Salvador',
            sy: 'Syrian Arab Republic',
            sz: 'Swaziland',
            tc: 'Turks and Caicos Islands',
            td: 'Chad',
            tf: 'French Southern Territories',
            tg: 'Togo',
            th: 'Thailand',
            tj: 'Tajikistan',
            tk: 'Tokelau',
            tl: 'Timor-Leste',
            tm: 'Turkmenistan',
            tn: 'Tunisia',
            to: 'Tonga',
            tr: 'Turkey',
            tt: 'Trinidad and Tobago',
            tv: 'Tuvalu',
            tw: 'Taiwan, Province of China',
            tz: 'Tanzania, United Republic of',
            ua: 'Ukraine',
            ug: 'Uganda',
            um: 'United States Minor Outlying Islands',
            us: 'United States',
            uy: 'Uruguay',
            uz: 'Uzbekistan',
            va: 'Holy See (Vatican City State)',
            vc: 'Saint Vincent and the Grenadines',
            ve: 'Venezuela, Bolivarian Republic of',
            vg: 'Virgin Islands, British',
            vi: 'Virgin Islands, U.S.',
            vn: 'Viet Nam',
            vu: 'Vanuatu',
            wf: 'Wallis and Futuna',
            ws: 'Samoa',
            ye: 'Yemen',
            yt: 'Mayotte',
            za: 'South Africa',
            zm: 'Zambia',
            zw: 'Zimbabwe',
        },
    },

    entity: {
        user: 'User',
        organization: 'Organization',
    },

    permissions: {
        label: 'Permissions',
        description: 'Grant or revoke permissions from the organization.',
        limitations: {
            label: 'Limitations',
            description: 'Apply usage limitations to the organization.',
        },
        'module.discovery': {
            label: 'Discovery module',
            description: 'Grants access to the discovery module',
        },
        'module.lists': {
            label: 'Lists module',
            description: 'Grants access to the lists module',
        },
        'module.games': {
            label: 'Games module',
            description: 'Grants access to the games module',
        },
        'module.campaigns': {
            label: 'Campaigns module',
            description: 'Grants access to the campaigns module',
        },
        'feature.global.emails': {
            label: 'Global Emails',
            description: 'Determines if emails should be accessible on creator profiles',
        },
        'feature.global.creator_search': {
            label: 'Global Creator Search',
            description: 'The search bar at the top of the page that lets you search anyone',
        },
        'feature.discovery.twitch': {
            label: 'Discovery Twitch',
            description: 'Grants access to the Twitch platform within the discovery module',
        },
        'feature.discovery.youtube': {
            label: 'Discovery YouTube',
            description: 'Grants access to the YouTube platform within the discovery module',
        },
        'feature.discovery.tiktok': {
            label: 'Discovery TikTok',
            description: 'Grants access to the TikTok platform within the discovery module',
        },
        'feature.discovery.twitter': {
            label: 'Discovery Twitter',
            description: 'Grants access to the Twitter platform within the discovery module',
        },
        'feature.discovery.instagram': {
            label: 'Discovery Instagram',
            description: 'Grants access to the Instagram platform within the discovery module',
        },
        'feature.lists.export': {
            label: 'Export Lists',
            description: 'Enables exporting of lists',
        },
        'feature.ai.bio': {
            label: 'AI Creator Biography',
            description: 'Enables the AI generated bio within the creator profile',
        },
        'feature.audience.demographics': {
            label: 'Audience Demographics',
            description: 'Enables the demographics tab on creator profiles',
        },
        'feature.audience.interests': {
            label: 'Audience Interests',
            description: 'Enables the audience data on creator profiles',
        },
        'feature.campaigns.manage': {
            label: 'Manage Campaign',
            description: 'Enables operators to create their own campaigns using workflow',
        },
        'usage.tier.small': {
            label: 'Starter Plan',
            description: 'Sets Starter Plan usage limitations (lists etc)',
        },
        'usage.tier.medium': {
            label: 'Growth Plan',
            description: 'Sets Growth Plan usage limitations (lists etc)',
        },
        'usage.tier.large': {
            label: 'Scale Plan',
            description: 'Sets Scale Plan usage limitations (lists etc)',
        },
        'data.timerange.triennial': {
            label: 'Triennial historical data',
            description: 'Allows viewing of data from up to three years ago',
        },
    },

    usage: {
        lists: {
            total: 'Your current plan allows the creation of up to [LIMIT] lists.',
            size: 'Your current plan allows a maximum of [LIMIT] creators on a list.',
        },
    },

    subscriptions: {
        label: 'Subscriptions',
        description: 'Manage your organizations subscriptions.',
        current: {
            label: 'Current subscriptions',
            description: 'Managage your organizations current subscriptions.',
        },
        available: {
            label: 'Available subscriptions',
            description: 'Choose a suitable subscription for your organization',
        },
    },

    discovery: {
        groups: {
            content: {
                label: 'Content',
            },
            channel: {
                label: 'Channel',
            },
            games: {
                label: 'Games',
            },
            'audience-demographics': {
                label: 'Audience Demographics',
            },
        },
    },

    etl: {
        youtube: {
            videoCategory: {
                1: 'Film & Animation',
                2: 'Autos & Vehicles',
                10: 'Music',
                15: 'Pets & Animals',
                17: 'Sports',
                18: 'Short Movies',
                19: 'Travel & Events',
                20: 'Gaming',
                21: 'Videoblogging',
                22: 'People & Blogs',
                23: 'Comedy',
                24: 'Entertainment',
                25: 'News & Politics',
                26: 'Howto & Style',
                27: 'Education',
                28: 'Science & Technology',
                29: 'Nonprofits & Activism',
                30: 'Movies',
                31: 'Anime/Animation',
                32: 'Action/Adventure',
                33: 'Classics',
                34: 'Comedy',
                35: 'Documentary',
                36: 'Drama',
                37: 'Family',
                38: 'Foreign',
                39: 'Horror',
                40: 'Sci-Fi/Fantasy',
                41: 'Thriller',
                42: 'Shorts',
                43: 'Shows',
                44: 'Trailers',
            },
        },
    },
}

export default {
    state,
    getters: {
        dictionary: (_state) => _state,

        getFromDictionary: (_state) => (path, field) => {
            const item = _.get(_state, path)

            if (field) {
                return item ? _.get(item, field) : ''
            }
            return item || ''
        },

        findFromDictionary: (_state) => (path, property, value) => {
            const items = _.get(_state, path)

            if (items) {
                const item = _.find(items, (_item) => _item[property] === value)

                if (item) {
                    return item
                }
            }

            return {}
        },
    },
}

export const getFromDictionary = (path, field) => {
    const item = _.get(state, path)

    if (field) {
        return item ? _.get(item, field) : ''
    }
    return item || ''
}
