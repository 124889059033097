<template>
    <div>
        <ModalBlankWide
            id="outreach-preview-modal"
            :modal-open="state.modals.outreachPreview.is_open"
            @close-modal="state.modals.outreachPreview.is_open = false"
        >
            <div ref="modalContent" class="flex flex-col">
                <!-- Modal header -->
                <div class="border-b border-slate-200 px-5 py-3">
                    <div class="fi flex items-center justify-between">
                        <div class="font-semibold text-slate-800">Outreach Preview</div>
                        <button
                            class="text-slate-400 hover:text-slate-500"
                            @click.stop="state.modals.outreachPreview.is_open = false"
                        >
                            <div class="sr-only">Close</div>
                            <svg class="h-4 w-4 fill-current">
                                <path
                                    d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="max-h-[80vh] overflow-y-scroll" v-html="outreachPreviewRaw" />
            </div>
        </ModalBlankWide>

        <!-- Modal dialog -->
        <ModalBlankWide
            :modal-open="state.modals.unsavedChanges.is_open"
            @close-modal="state.modals.unsavedChanges.is_open = false"
        >
            <div class="p-5">
                <div class="flex space-x-4">
                    <!-- Icon -->
                    <div class="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-indigo-100">
                        <svg class="h-4 w-4 shrink-0 fill-current text-indigo-500" viewBox="0 0 16 16">
                            <path
                                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
                            />
                        </svg>
                    </div>

                    <!-- Content -->
                    <div class="grow">
                        <!-- Modal header -->
                        <div class="mb-2">
                            <div class="text-lg font-semibold text-slate-800">You have unsaved changes.</div>
                        </div>

                        <!-- Modal content -->
                        <div class="mb-10 text-sm">
                            <div class="space-y-2">
                                <p>You may choose to cancel, discard changes, or save changes.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Modal footer -->
                <div class="flex flex-wrap justify-end space-x-2">
                    <button
                        class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                        @click.stop="state.modals.unsavedChanges.is_open = false"
                    >
                        Cancel
                    </button>
                    <button
                        class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600"
                        @click.stop="onDiscardAndNavigate()"
                    >
                        Discard changes
                    </button>
                    <button
                        class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600"
                        @click.stop="onSaveAndNavigate()"
                    >
                        Save changes
                    </button>
                </div>
            </div>
        </ModalBlankWide>

        <div class="col-span-full rounded-sm border border-slate-200 bg-white">
            <div class="py-4">
                <div class="flex flex-col">
                    <div class="px-6">
                        <!-- Tabs -->
                        <div class="relative">
                            <div class="absolute bottom-0 h-px w-full bg-slate-200" aria-hidden="true"></div>

                            <ul
                                class="no-scrollbar relative -mx-4 flex flex-nowrap overflow-x-scroll text-sm font-medium sm:-mx-6 lg:-mx-8"
                            >
                                <li
                                    class="mr-6 first:pl-4 last:mr-0 last:pr-4 sm:first:pl-6 sm:last:pr-6 lg:first:pl-8 lg:last:pr-8"
                                >
                                    <a
                                        class="block cursor-pointer whitespace-nowrap py-3"
                                        :class="{
                                            'border-b-2 border-indigo-500 text-indigo-500': activeTab === 'settings',
                                        }"
                                        @click="setActiveTab('settings', null)"
                                        >Settings</a
                                    >
                                </li>

                                <li
                                    v-for="campaign in $lodash.filter(
                                        campaignsById,
                                        (campaign) => campaign.type === 'game-sponsorship',
                                    )"
                                    :key="campaign.id"
                                    class="mr-6 first:pl-4 last:mr-0 last:pr-4 sm:first:pl-6 sm:last:pr-6 lg:first:pl-8 lg:last:pr-8"
                                >
                                    <a
                                        class="block cursor-pointer whitespace-nowrap py-3"
                                        :class="{
                                            'border-b-2 border-indigo-500 text-indigo-500':
                                                activeTab === 'game-sponsorship',
                                        }"
                                        @click="setActiveTab('game-sponsorship', campaign.id)"
                                        >{{ getFromDictionary(`campaign.type.${campaign.type}`, 'label') }}</a
                                    >
                                </li>

                                <li
                                    v-for="campaign in $lodash.filter(
                                        campaignsById,
                                        (campaign) => campaign.type === 'key-distribution',
                                    )"
                                    :key="campaign.id"
                                    class="mr-6 first:pl-4 last:mr-0 last:pr-4 sm:first:pl-6 sm:last:pr-6 lg:first:pl-8 lg:last:pr-8"
                                >
                                    <a
                                        class="block cursor-pointer whitespace-nowrap py-3"
                                        :class="{
                                            'border-b-2 border-indigo-500 text-indigo-500':
                                                activeTab === 'key-distribution',
                                        }"
                                        @click="setActiveTab('key-distribution', campaign.id)"
                                        >{{ getFromDictionary(`campaign.type.${campaign.type}`, 'label') }}</a
                                    >
                                </li>

                                <li
                                    v-for="campaign in $lodash.filter(
                                        campaignsById,
                                        (campaign) => campaign.type === 'steam-broadcast',
                                    )"
                                    :key="campaign.id"
                                    class="mr-6 first:pl-4 last:mr-0 last:pr-4 sm:first:pl-6 sm:last:pr-6 lg:first:pl-8 lg:last:pr-8"
                                >
                                    <a
                                        class="block cursor-pointer whitespace-nowrap py-3"
                                        :class="{
                                            'border-b-2 border-indigo-500 text-indigo-500':
                                                activeTab === 'steam-broadcast',
                                        }"
                                        @click="setActiveTab('steam-broadcast', campaign.id)"
                                        >{{ getFromDictionary(`campaign.type.${campaign.type}`, 'label') }}</a
                                    >
                                </li>

                                <li v-if="supercampaignGame">
                                    <a
                                        class="block cursor-pointer whitespace-nowrap py-3"
                                        :class="{
                                            'border-b-2 border-indigo-500 text-indigo-500': activeTab === 'manage-keys',
                                        }"
                                        @click="setActiveTab('manage-keys', null)"
                                        >Key Management</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div v-if="activeTab === 'settings'" class="flex flex-col divide-y">
                        <div class="grid gap-4 px-6 py-8 md:grid-cols-12">
                            <div class="col-span-12 md:col-span-4 md:pr-10">
                                <h2 class="font-semibold text-slate-800">Campaign Settings</h2>
                                <div class="space-y-2 text-sm">
                                    <p>
                                        These are high level settings that apply to the campaign and all of it's
                                        modules.
                                    </p>
                                </div>
                            </div>

                            <div class="col-span-12 md:col-span-5">
                                <div class="grid gap-4 md:grid-cols-12">
                                    <div class="col-span-12 md:col-span-12">
                                        <label class="mb-2 block text-sm font-medium">Campaign Title</label>
                                        <input
                                            v-model="supercampaignToShow.title"
                                            class="form-input w-full"
                                            type="text"
                                            @input="onInputChange('supercampaign')"
                                        />
                                        <div class="mt-1 text-xs">
                                            This is the title that is shown to creators. Title should indicate the
                                            purpose of the campaign. Example: "Fortnite Closed Beta Campaign"
                                        </div>
                                    </div>

                                    <div class="col-span-12 md:col-span-12">
                                        <label class="mb-2 block text-sm font-medium">Campaign Description</label>
                                        <textarea
                                            v-model="supercampaignToShow.description"
                                            class="form-input h-32 w-full"
                                            @input="onInputChange('supercampaign')"
                                        />
                                        <div class="mt-1 text-xs">
                                            This describes what you are trying to achieve for the campaign to creators.
                                            Ex: "We are looking for creators to showcase the newly released DLC."
                                        </div>
                                    </div>

                                    <div class="col-span-12 md:col-span-12">
                                        <label class="mb-2 block text-sm font-medium">Game Lifecycle Stage</label>
                                        <select
                                            v-model="supercampaignToShow.stage"
                                            class="form-select w-full"
                                            @change="onInputChange('supercampaign')"
                                        >
                                            <option
                                                v-for="cyclestage in lifecycleStages"
                                                :key="cyclestage.label"
                                                :value="cyclestage.value"
                                            >
                                                {{ cyclestage.label }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-span-12 md:col-span-12">
                                        <label class="mb-2 block text-sm font-medium">Associated Game</label>

                                        <CodeSelect
                                            class="w-full"
                                            title=""
                                            code-type="game"
                                            :preload="true"
                                            select-mode="single"
                                            :max-items="5000"
                                            search-placeholder="Search for a game"
                                            :selected-options="[supercampaignGame]"
                                            :is-caret-enabled="true"
                                            platform="igdb"
                                            no-results-text="All matches selected"
                                            @selection-change="(newGame) => onGameChange(newGame)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="['game-sponsorship', 'key-distribution'].includes(activeTab) && stageSettingsToShow"
                        :key="activeTab"
                        class="flex flex-col"
                    >
                        <div class="divide-y">
                            <div class="grid gap-4 px-6 py-8 md:grid-cols-12">
                                <div class="col-span-12 md:col-span-4 md:pr-10">
                                    <h2 class="font-semibold text-slate-800">
                                        {{ getFromDictionary(`campaign.type.game-sponsorship`, 'label') }} Settings
                                    </h2>
                                    <div class="space-y-2 text-sm">
                                        <p>
                                            These are settings that determine how your
                                            {{ getFromDictionary(`campaign.type.game-sponsorship`, 'label') }} campaign
                                            is run.
                                        </p>
                                    </div>
                                </div>

                                <div class="col-span-12 md:col-span-5">
                                    <div class="grid gap-4 md:grid-cols-12">
                                        <div class="col-span-12 md:col-span-12">
                                            <label class="mb-2 block text-sm font-medium">Title</label>
                                            <input
                                                v-model="campaignToShow.title"
                                                class="form-input w-full"
                                                type="text"
                                                @input="onInputChange('gameSponsorship')"
                                            />
                                        </div>

                                        <div class="col-span-12 md:col-span-12">
                                            <label class="mb-2 block text-sm font-medium">Description</label>
                                            <textarea
                                                v-model="campaignToShow.description"
                                                class="form-input w-full"
                                                rows="5"
                                                @input="onInputChange('gameSponsorship')"
                                            />
                                        </div>

                                        <template v-if="['game-sponsorship'].includes(activeTab)">
                                            <div class="col-span-12 md:col-span-6">
                                                <label class="mb-2 block text-sm font-medium">Budget</label>
                                                <!-- <input class="form-input w-full" type="number" v-model="campaignToShow.budget_amount" /> -->

                                                <CurrencyInput
                                                    v-model="campaignToShow.budget_amount"
                                                    class="w-full"
                                                    :options="{ currency: 'USD' }"
                                                    @input="onInputChange('gameSponsorship')"
                                                />
                                            </div>
                                            <div v-if="isSfStaff" class="col-span-12 md:col-span-6">
                                                <label class="mb-2 block text-sm font-medium">Commission rate</label>
                                                <input
                                                    v-model="campaignToShow.commission_rate"
                                                    class="form-input w-full"
                                                    type="number"
                                                    @input="onInputChange('gameSponsorship')"
                                                />
                                            </div>

                                            <div v-else class="col-span-12 md:col-span-6"></div>
                                        </template>

                                        <div class="col-span-12 md:col-span-6">
                                            <label class="mb-2 block text-sm font-medium">Start date</label>

                                            <div class="relative">
                                                <flat-pickr
                                                    v-model="campaignToShow.started_at"
                                                    class="form-input w-full pl-9 font-medium text-slate-500 hover:text-slate-600 focus:border-slate-300"
                                                    :config="pickerConfig"
                                                    @input="onInputChange('gameSponsorship')"
                                                />
                                                <div
                                                    class="pointer-events-none absolute inset-0 right-auto flex items-center"
                                                >
                                                    <!-- <font-awesome-icon :icon="['fas', 'calendar']" class="w-4 h-4 fill-current text-slate-500 ml-3" /> -->
                                                    <svg
                                                        class="ml-3 h-4 w-4 fill-current text-slate-500"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path
                                                            d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-span-12 md:col-span-6">
                                            <label class="mb-2 block text-sm font-medium">End date</label>

                                            <div class="relative">
                                                <flat-pickr
                                                    v-model="campaignToShow.ended_at"
                                                    class="form-input w-full pl-9 font-medium text-slate-500 hover:text-slate-600 focus:border-slate-300"
                                                    :config="pickerConfig"
                                                    @input="onInputChange('gameSponsorship')"
                                                />
                                                <div
                                                    class="pointer-events-none absolute inset-0 right-auto flex items-center"
                                                >
                                                    <!-- <font-awesome-icon :icon="['fas', 'calendar']" class="w-4 h-4 fill-current text-slate-500 ml-3" /> -->
                                                    <svg
                                                        class="ml-3 h-4 w-4 fill-current text-slate-500"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path
                                                            d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-span-12 md:col-span-12">
                                            <label class="mb-2 block text-sm font-medium">Panel image</label>
                                            <input
                                                v-model="stageSettingsToShow.preparation.panel_image"
                                                class="form-input w-full"
                                                type="text"
                                                placeholder="https://i.imgur.com/"
                                                @input="onInputChange('gameSponsorship')"
                                            />
                                        </div>

                                        <div class="col-span-12 md:col-span-12">
                                            <label class="mb-2 block text-sm font-medium"
                                                >Tracking link destination</label
                                            >
                                            <input
                                                v-model="stageSettingsToShow.preparation.clickthrough_destination"
                                                class="form-input w-full"
                                                type="text"
                                                placeholder="https://store.steampowered.com/app/"
                                                @input="onInputChange('gameSponsorship')"
                                            />
                                        </div>

                                        <div class="col-span-12 md:col-span-12">
                                            <label class="mb-2 block text-sm font-medium">Project brief</label>
                                            <DocumentUpload
                                                :organization-id="supercampaign.organization_id"
                                                entity="campaign"
                                                :entity-id="state.activeCampaignId"
                                                document-type="campaign-brief"
                                            >
                                            </DocumentUpload>
                                        </div>

                                        <!-- <div class="col-span-12 md:col-span-12">
                                        <label class="block text-sm font-medium mb-2">Creator must provide stream time</label>
                                        <div class="form-switch">
                                            <input type="checkbox"
                                                class="sr-only"
                                                id="game_sponsorship_is_window_expected"
                                                v-model="campaignToShow.payload.stream_delivery.is_window_expected"
                                            />
                                            <label class="bg-slate-400" for="game_sponsorship_is_window_expected">
                                                <span class="bg-white shadow-sm" aria-hidden="true"></span>
                                                <span class="sr-only">Toggle</span>
                                            </label>
                                        </div>
                                    </div> -->
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="grid md:grid-cols-12 gap-4 py-8 px-6">
                                <div class="col-span-12 md:col-span-4 md:pr-10">
                                    <h2 class="text-slate-800 font-semibold">Default Deliverables</h2>
                                    <div class="text-sm space-y-2">
                                        <p>
                                            Here you can define the default things you are looking for each creator hired to
                                            deliver on. You can use this as a template and customize deliverables on a
                                            per-creator basis.
                                        </p>
                                    </div>
                                </div>

                                <Tasks
                                    :platforms="enabledPlatforms"
                                    :supercampaignId="this.supercampaign.id"
                                    :campaign="this.campaign"
                                    :allowAdd="true"
                                    :allowEdit="true"
                                    :hideEmptyPlatform="false"
                                    :showEmptyWarning="true"
                                />
                            </div> -->

                            <div class="grid gap-4 px-6 py-8 md:grid-cols-12">
                                <div class="col-span-12 md:col-span-4 md:pr-10">
                                    <h2 class="font-semibold text-slate-800">Task Constraints</h2>
                                    <div class="space-y-2 text-sm">
                                        <p>
                                            Here you can define the default things you are looking for each creator
                                            hired to deliver on. You can use this as a template and customize
                                            deliverables on a per-creator basis.
                                        </p>
                                    </div>
                                </div>

                                <TasksConstraints
                                    :platform-ids="enabledPlatforms"
                                    :supercampaign-id="supercampaignToShow.id"
                                    :task_constraints="settings?.task_constraints"
                                    :campaign="campaign"
                                    @constraint-updated="
                                        (campaignId, constraints) => onConstraintsChange(campaignId, constraints)
                                    "
                                />
                            </div>

                            <div class="grid gap-4 px-6 py-8 md:grid-cols-12">
                                <div class="col-span-12 md:col-span-4 md:pr-10">
                                    <h2 class="font-semibold text-slate-800">Key assignment</h2>
                                    <div class="space-y-2 text-sm">
                                        <p>
                                            Here you can select up to three key groups that will automatically create
                                            key retrieval tasks for the creator upon bid acceptance, unless a key
                                            retrieval task has been manually created.
                                        </p>
                                    </div>
                                </div>

                                <div class="col-span-12 md:col-span-5">
                                    <div class="col-span-12 md:col-span-12">
                                        <label class="mb-2 block text-sm font-medium">Primary key group</label>
                                        <select
                                            v-model="stageSettingsToShow.prospect.primary_retrieve_key_group_id"
                                            class="form-select w-full"
                                        >
                                            <option :value="null" selected>Please select</option>
                                            <option
                                                v-for="group in supercampaignKeyGroups"
                                                :key="group.id"
                                                :value="group.id"
                                            >
                                                {{ group.title }} ({{ group.unclaimed_key_count }} keys)
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-span-12 mt-2 md:col-span-12">
                                        <label class="mb-2 block text-sm font-medium">Secondary key group</label>
                                        <select
                                            v-model="stageSettingsToShow.prospect.secondary_retrieve_key_group_id"
                                            class="form-select w-full"
                                        >
                                            <option :value="null" selected>Please select</option>
                                            <option
                                                v-for="group in supercampaignKeyGroups"
                                                :key="group.id"
                                                :value="group.id"
                                            >
                                                {{ group.title }} ({{ group.unclaimed_key_count }} keys)
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-span-12 mt-2 md:col-span-12">
                                        <label class="mb-2 block text-sm font-medium">Tertiary key group</label>
                                        <select
                                            v-model="stageSettingsToShow.prospect.tertiary_retrieve_key_group_id"
                                            class="form-select w-full"
                                        >
                                            <option :value="null" selected>Please select</option>
                                            <option
                                                v-for="group in supercampaignKeyGroups"
                                                :key="group.id"
                                                :value="group.id"
                                            >
                                                {{ group.title }} ({{ group.unclaimed_key_count }} keys)
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="grid gap-4 px-6 py-8 md:grid-cols-12">
                                <div class="col-span-12 md:col-span-4 md:pr-10">
                                    <h2 class="font-semibold text-slate-800">Creator Outreach</h2>
                                    <div class="space-y-2 text-sm">
                                        <p>
                                            Here you can adjust the communications with creators when they are contacted
                                            about your campaign.
                                        </p>
                                        <div class="mt-5">
                                            <button
                                                class="flex cursor-pointer rounded bg-indigo-500 px-3 py-1 text-sm font-semibold text-white hover:bg-indigo-600"
                                                @click="togglePreviewOutreach"
                                            >
                                                Preview
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-if="stageSettingsToShow?.prospect?.outreach_config"
                                    class="col-span-12 md:col-span-5"
                                >
                                    <div class="grid gap-4 md:grid-cols-12">
                                        <div
                                            v-for="outreach in outreachProperties"
                                            :key="outreach.prop"
                                            class="col-span-12 md:col-span-12"
                                        >
                                            <div class="mb-1 flex">
                                                <label class="mb-2 block text-sm font-medium">{{
                                                    outreach.title
                                                }}</label>
                                                <div v-if="outreach.canDisable" class="form-switch ml-auto">
                                                    <input
                                                        :id="outreach.enabledFlag"
                                                        v-model="
                                                            stageSettingsToShow.prospect.outreach_config[
                                                                outreach.enabledFlag
                                                            ]
                                                        "
                                                        type="checkbox"
                                                        class="sr-only"
                                                        @change="onInputChange()"
                                                    />
                                                    <label class="bg-slate-400" :for="outreach.enabledFlag">
                                                        <span class="bg-white shadow-sm" aria-hidden="true"></span>
                                                        <span class="sr-only">Toggle</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    stageSettingsToShow.prospect.outreach_config[
                                                        outreach.enabledFlag
                                                    ] || !outreach.canDisable
                                                "
                                            >
                                                <textarea
                                                    v-if="outreach.inputType === 'textarea'"
                                                    v-model="
                                                        stageSettingsToShow.prospect.outreach_config[outreach.prop]
                                                    "
                                                    class="form-input h-32 w-full"
                                                    rows="5"
                                                    :placeholder="outreachDefaults.fields[outreach.placeholder]"
                                                    @input="onInputChange()"
                                                />

                                                <input
                                                    v-if="outreach.inputType === 'text'"
                                                    v-model="
                                                        stageSettingsToShow.prospect.outreach_config[outreach.prop]
                                                    "
                                                    class="form-input w-full"
                                                    type="text"
                                                    :placeholder="outreachDefaults.fields[outreach.placeholder]"
                                                    @input="onInputChange()"
                                                />

                                                <div v-if="outreach.description" class="mt-1 text-xs">
                                                    {{ outreach.description }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Disable Workflow stages -->
                            <div v-if="isSfStaff" class="grid gap-4 px-6 py-8 md:grid-cols-12">
                                <div class="col-span-12 md:col-span-4 md:pr-10">
                                    <h2 class="font-semibold text-slate-800">Workflow Stages</h2>
                                    <div class="space-y-2 text-sm">
                                        <p>Here you can adjust which workflow stages are enabled for this campaign.</p>
                                    </div>
                                </div>

                                <div class="col-span-12 md:col-span-5">
                                    <div class="grid gap-4 md:grid-cols-12">
                                        <div class="col-span-12 md:col-span-12">
                                            <div
                                                v-for="stage in worklowStageSettingsToShowForDisabling"
                                                :key="stage.id"
                                            >
                                                <div class="m-3 flex justify-between">
                                                    <span class="text-sm font-medium capitalize">
                                                        {{ stage.stage }}</span
                                                    >
                                                    <div class="form-switch">
                                                        <input
                                                            :id="stage.id"
                                                            type="checkbox"
                                                            class="sr-only"
                                                            :checked="!stage.is_disabled"
                                                            @change="onDisableStage(stage, 'gameSponsorship')"
                                                        />
                                                        <label class="bg-slate-400" :for="stage.id">
                                                            <span class="bg-white shadow-sm" aria-hidden="true"></span>
                                                            <span class="sr-only">Toggle</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="rounded-sm border border-amber-200 bg-amber-100 px-4 py-2 text-sm text-amber-600"
                                            >
                                                <div class="flex w-full items-start justify-between gap-2">
                                                    <div class="flex items-center">
                                                        <font-awesome-icon
                                                            :icon="['fas', 'triangle-exclamation']"
                                                            class="mr-3 h-6 w-6 text-amber-500"
                                                        />
                                                        <div>
                                                            Disabling a stage with participants can lead to issues in
                                                            the campaign.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="activeTab === 'steam-broadcast'" class="flex flex-col divide-y">
                        <div class="grid gap-4 px-6 py-8 md:grid-cols-12">
                            <div class="col-span-12 md:col-span-4 md:pr-10">
                                <h2 class="font-semibold text-slate-800">
                                    {{ getFromDictionary(`campaign.type.steam-broadcast`, 'label') }} Settings
                                </h2>
                                <div class="space-y-2 text-sm">
                                    <p>
                                        General
                                        {{ getFromDictionary(`campaign.type.steam-broadcast`, 'label') }} configuration
                                    </p>
                                </div>
                            </div>

                            <div class="col-span-12 md:col-span-5">
                                <div class="grid gap-4 md:grid-cols-12">
                                    <div class="col-span-12 md:col-span-12">
                                        <label class="mb-2 block text-sm font-medium">Title</label>
                                        <input
                                            v-model="campaignToShow.title"
                                            class="form-input w-full"
                                            type="text"
                                            @input="onInputChange('steamBroadcast')"
                                        />
                                    </div>

                                    <div class="col-span-12 md:col-span-12">
                                        <label class="mb-2 block text-sm font-medium">Description</label>
                                        <textarea
                                            v-model="campaignToShow.description"
                                            class="form-input w-full"
                                            @input="onInputChange('steamBroadcast')"
                                        />
                                    </div>

                                    <div class="col-span-12 md:col-span-6">
                                        <label class="mb-2 block text-sm font-medium">Budget</label>
                                        <CurrencyInput
                                            v-model="campaignToShow.budget_amount"
                                            class="w-full"
                                            :options="{ currency: 'USD' }"
                                            @input="onInputChange('steamBroadcast')"
                                        />
                                    </div>

                                    <div class="col-span-12 md:col-span-6"></div>

                                    <div class="col-span-12 md:col-span-6">
                                        <label class="mb-2 block text-sm font-medium">Start date</label>
                                        <div class="relative">
                                            <flat-pickr
                                                v-model="campaignToShow.started_at"
                                                class="form-input w-full pl-9 font-medium text-slate-500 hover:text-slate-600 focus:border-slate-300"
                                                :config="pickerConfig"
                                                @input="onInputChange('steamBroadcast')"
                                            />
                                            <div
                                                class="pointer-events-none absolute inset-0 right-auto flex items-center"
                                            >
                                                <!-- <font-awesome-icon :icon="['fas', 'calendar']" class="w-4 h-4 fill-current text-slate-500 ml-3" /> -->
                                                <svg
                                                    class="ml-3 h-4 w-4 fill-current text-slate-500"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-span-12 md:col-span-6">
                                        <label class="mb-2 block text-sm font-medium">End date</label>
                                        <div class="relative">
                                            <flat-pickr
                                                v-model="campaignToShow.ended_at"
                                                class="form-input w-full pl-9 font-medium text-slate-500 hover:text-slate-600 focus:border-slate-300"
                                                :config="pickerConfig"
                                                @input="onInputChange('steamBroadcast')"
                                            />
                                            <div
                                                class="pointer-events-none absolute inset-0 right-auto flex items-center"
                                            >
                                                <!-- <font-awesome-icon :icon="['fas', 'calendar']" class="w-4 h-4 fill-current text-slate-500 ml-3" /> -->
                                                <svg
                                                    class="ml-3 h-4 w-4 fill-current text-slate-500"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-span-12 md:col-span-6">
                                        <label class="mb-2 block text-sm font-medium">Hours per slot</label>
                                        <input
                                            v-model="campaignToShow.payload.slots.duration_hours"
                                            class="form-input w-full"
                                            type="number"
                                            @input="onInputChange('steamBroadcast')"
                                        />
                                    </div>

                                    <div class="col-span-12 md:col-span-6">
                                        <label class="mb-2 block text-sm font-medium">Payout per slot</label>
                                        <CurrencyInput
                                            v-model="campaignToShow.payload.slots.payout_per_slot"
                                            class="w-full"
                                            :options="{ currency: 'USD' }"
                                            @input="onInputChange('steamBroadcast')"
                                        />
                                    </div>

                                    <div class="col-span-12 md:col-span-6">
                                        <label class="mb-2 block text-sm font-medium">Schedule Status</label>
                                        <select
                                            v-model="campaignToShow.payload.slots.state"
                                            class="form-select w-full"
                                            @select="onInputChange('steamBroadcast')"
                                        >
                                            <option
                                                v-for="option in scheduleStatus"
                                                :key="option.label"
                                                :value="option.value"
                                            >
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-span-12 md:col-span-6">
                                        <label class="mb-2 block text-sm font-medium">Applications Status</label>
                                        <select
                                            v-model="campaignToShow.payload.applications.state"
                                            class="form-select w-full"
                                            @select="onInputChange('steamBroadcast')"
                                        >
                                            <option
                                                v-for="option in applicationsState"
                                                :key="option.label"
                                                :value="option.value"
                                            >
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-span-12 md:col-span-6">
                                        <label class="mb-2 block text-sm font-medium">RTMP URL</label>
                                        <input
                                            v-model="campaignToShow.payload.ingest_server.rtmp_url"
                                            class="form-input w-full"
                                            type="text"
                                            @input="onInputChange('steamBroadcast')"
                                        />
                                    </div>

                                    <div class="col-span-12 md:col-span-6">
                                        <label class="mb-2 block text-sm font-medium">RTMP Stream Key</label>
                                        <input
                                            v-model="campaignToShow.payload.ingest_server.stream_key"
                                            class="form-input w-full"
                                            type="text"
                                            @input="onInputChange('steamBroadcast')"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="activeTab === 'settings'" class="col-span-12">
                        <div class="flex justify-end space-x-4 px-4">
                            <button
                                :class="
                                    !isDirty.supercampaign
                                        ? 'btn cursor-not-allowed border-slate-200 bg-white text-slate-700 opacity-50 '
                                        : 'btn border-slate-200 bg-white text-slate-700 hover:border-slate-300'
                                "
                                :disabled="!isDirty.supercampaign"
                                @click="cancelSupercampaignSettings()"
                            >
                                Cancel
                            </button>
                            <button
                                class="btn bg-indigo-500 text-white hover:bg-indigo-600"
                                @click="saveSuperCampaignSettings()"
                            >
                                Save Settings
                            </button>
                        </div>
                    </div>

                    <div v-else-if="activeTab === 'manage-keys'" class="flex flex-col divide-y">
                        <div class="mx-6 mt-4">
                            <ManageGameKeys
                                :supercampaign="supercampaign"
                                :campaign="campaign"
                                @group-change="onKeyGroupChange"
                            />
                        </div>
                    </div>

                    <div v-else class="col-span-12">
                        <div class="flex justify-end space-x-4 px-4">
                            <button
                                :class="
                                    !isActiveCampaignDirty
                                        ? 'btn cursor-not-allowed border-slate-200 bg-white text-slate-700 opacity-50 '
                                        : 'btn border-slate-200 bg-white text-slate-700 hover:border-slate-300'
                                "
                                :disabled="!isActiveCampaignDirty"
                                @click="cancelCampaignSettings()"
                            >
                                Cancel
                            </button>
                            <button
                                class="btn bg-indigo-500 text-white hover:bg-indigo-600"
                                @click="saveCurrentCampaignSettings()"
                            >
                                Save Settings
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import ModalBlankWide from '@/components/ModalBlankWide.vue'
import CodeSelect from '@/components/CodeSelect.vue'
import CurrencyInput from '@/components/CurrencyInput.vue'
import TasksConstraints from '@/partials/campaigns/TasksConstraints.vue'
import ManageGameKeys from '@/partials/campaigns/ManageKeys.vue'
import DocumentUpload from '@/components/DocumentUpload.vue'
import superCampaignAPI from '../utils/api/api.supercampaign'
import campaignAPI from '../utils/api/api.campaign'
import campaignSettingsAPI from '../utils/api/api.campaign.settings'
import campaignKeysAPI from '../utils/api/api.campaign.keys'

export default {
    name: 'SupercampaignSettingsSubpage',

    components: {
        ModalBlankWide,
        flatPickr,
        CodeSelect,
        CurrencyInput,
        TasksConstraints,
        ManageGameKeys,
        DocumentUpload,
    },

    props: ['supercampaign', 'campaignsById'],

    data() {
        return {
            enabledCampaignTypes: ['game-sponsorship', 'key-distribution', 'steam-broadcast'],
            enabledPlatforms: ['twitch', 'youtube', 'tiktok', 'twitter', 'instagram'],

            stages: ['prospect', 'pricing', 'contracting', 'preparation', 'delivery'],
            activeTab: 'settings',

            campaign: {},

            stageSettings: {
                prospect: {},
                pricing: {},
                contracting: {},
                preparation: {},
                delivery: {},
            },

            supercampaignGame: null,

            settingsByCampaignId: {},
            stageSettingsByCampaignId: {},

            state: {
                activeCampaignId: null,

                modals: {
                    outreachPreview: {
                        is_open: false,
                    },
                    unsavedChanges: {
                        is_open: false,
                        data: {
                            tab: null,
                            campaignId: null,
                        },
                    },
                },
            },

            pickerConfig: {
                enableTime: true,
                dateFormat: 'Z',
                altFormat: 'F j, Y h:i K',
                altInput: true,
            },

            selectedGame: null,

            outreachPreviewRaw: null,

            supercampaignToShow: null,
            campaignToShow: null,

            stageSettingsToShow: {
                prospect: {},
                pricing: {},
                contracting: {},
                preparation: {},
                delivery: {},
            },

            supercampaignKeyGroups: [],

            campaignsToCamelCase: {
                'game-sponsorship': 'gameSponsorship',
                'key-distribution': 'keyDistribution',
                'steam-broadcast': 'steamBroadcast',
            },

            isDirty: {
                supercampaign: false,
                campaign: {},
            },

            outreachProperties: [
                {
                    prop: 'campaign_type',
                    title: 'Campaign title',
                    description: '',
                    placeholder: 'campaign_type',
                    inputType: 'text',
                    enabledFlag: 'campaign_type_enabled',
                    canDisable: true,
                },
                {
                    prop: 'description',
                    title: 'Opportunity description',
                    description: 'Tell creators about the game, and brief details about the campaign.',
                    placeholder: 'description',
                    inputType: 'textarea',
                    enabledFlag: 'description_enabled',
                    canDisable: true,
                },
                {
                    prop: 'hero_image_url',
                    title: 'Hero image url',
                    description: '',
                    placeholder: 'hero_image_url',
                    inputType: 'text',
                    enabledFlag: 'hero_image_url_enabled',
                    canDisable: true,
                },
                {
                    prop: 'cta_byline',
                    title: 'Call to action byline',
                    description: '',
                    placeholder: 'cta_byline',
                    inputType: 'textarea',
                    enabledFlag: 'cta_byline_enabled',
                    canDisable: true,
                },
                {
                    prop: 'cta_text',
                    title: 'Interested button',
                    description: '',
                    placeholder: 'cta_text',
                    inputType: 'text',
                    enabledFlag: null,
                    canDisable: false,
                },
                {
                    prop: 'cta_decline_text',
                    title: 'Not interested button',
                    description: '',
                    placeholder: 'cta_decline_text',
                    inputType: 'text',
                    enabledFlag: null,
                    canDisable: false,
                },
                {
                    prop: 'contact_us',
                    title: 'Contact us',
                    description: '',
                    placeholder: 'contact_us',
                    inputType: 'textarea',
                    enabledFlag: 'contact_us_enabled',
                    canDisable: true,
                },
                {
                    prop: 'branding_logo',
                    title: 'Branding logo',
                    description: '',
                    placeholder: 'branding_logo',
                    inputType: 'text',
                    enabledFlag: 'branding_logo_enabled',
                    canDisable: true,
                },
                {
                    prop: 'branding_logo_url',
                    title: 'Branding logo url',
                    description: '',
                    placeholder: 'branding_logo_url',
                    inputType: 'text',
                    enabledFlag: 'branding_logo_url_enabled',
                    canDisable: true,
                },
                {
                    prop: 'similar_games',
                    title: 'Similar games',
                    description: '',
                    placeholder: 'similar_games',
                    inputType: 'text',
                    enabledFlag: 'similar_games_enabled',
                    canDisable: true,
                },
            ],
        }
    },

    created() {
        this.setActiveCampaignId()
        this.getAllSupercampaignSettings()

        if (this.supercampaign.game && this.supercampaign.game.igdb_id) {
            this.supercampaignGame = {
                id: this.supercampaign.game.igdb_id,
                label: this.supercampaign.game.name,
                name: this.supercampaign.game.name,
                value: this.supercampaign.game.igdb_id,
            }
        }

        this.setActiveTab(this.$route.query.tab, this.$route.query.tabId)
        this.supercampaignToShow = _.cloneDeep(this.supercampaign)

        _.forEach(this.campaignsById, (value, key) => {
            this.isDirty.campaign[key] = false
        })
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'isSfStaff']),

        isActiveCampaignDirty() {
            return this.isDirty.campaign[this.state.activeCampaignId]
        },

        campaignsWithSettings() {
            return _.filter(this.campaignsById, (campaign) => this.enabledCampaignTypes.includes(campaign.type))
        },

        settings() {
            return this.settingsByCampaignId[this.state.activeCampaignId]
        },

        outreachDefaults() {
            if (this.campaign && this.settings) {
                return this.settings.defaults.outreach.configurationByCampaignType[this.campaign.type]
            }
            return {}
        },

        lifecycleStages() {
            return _.orderBy(this.getFromDictionary(`campaign`, 'lifecycle_stage'), ['order'], ['asc'])
        },

        scheduleStatus() {
            return _.orderBy(this.getFromDictionary(`campaign`, 'schedule_status'), ['order'], ['asc'])
        },

        applicationsState() {
            return _.orderBy(this.getFromDictionary(`campaign`, 'applications_state'), ['order'], ['asc'])
        },

        worklowStageSettingsToShowForDisabling() {
            const stagesThatCanBeDisabled = ['pricing', 'contracting']
            // This could be simplified if we convert all null `is_disabled` fields in the database to false
            return Object.keys(this.stageSettingsToShow)
                .filter((stage) => stagesThatCanBeDisabled.includes(stage))
                .map((stage) => ({
                    ...this.stageSettings[stage],
                    is_disabled:
                        this.stageSettingsToShow[stage].is_disabled === null
                            ? false
                            : this.stageSettingsToShow[stage].is_disabled,
                }))
        },
    },

    methods: {
        onDiscardAndNavigate() {
            const { tab, campaignId } = this.state.modals.unsavedChanges.data
            if (!campaignId) {
                this.cancelSupercampaignSettings()
            } else {
                this.cancelCampaignSettings()
            }

            this.state.modals.unsavedChanges.is_open = false
            this.setActiveTab(tab, campaignId)
        },

        onSaveAndNavigate() {
            const { tab, campaignId } = this.state.modals.unsavedChanges.data

            if (!campaignId) {
                this.saveSuperCampaignSettings()
            } else {
                this.saveCurrentCampaignSettings()
            }

            this.state.modals.unsavedChanges.is_open = false
            this.setActiveTab(tab, campaignId)
        },

        onInputChange(type) {
            if (type === 'supercampaign') {
                this.isDirty.supercampaign = true
            } else {
                this.isDirty.campaign[this.state.activeCampaignId] = true
            }
        },

        saveSuperCampaignSettings() {
            this.isDirty.supercampaign = false
            this.saveSuperCampaign(this.supercampaignToShow)
        },

        cancelSupercampaignSettings() {
            this.supercampaignToShow = _.cloneDeep(this.supercampaign)
            this.isDirty.supercampaign = false
        },

        saveCurrentCampaignSettings() {
            this.isDirty.campaign[this.state.activeCampaignId] = false

            this.saveStageSettings(this.state.activeCampaignId, 'preparation')
            this.saveStageSettings(this.state.activeCampaignId, 'prospect')
            this.saveStageSettings(this.state.activeCampaignId, 'pricing')
            this.saveStageSettings(this.state.activeCampaignId, 'contracting')
            this.saveCampaign(this.campaignToShow)
        },

        cancelCampaignSettings() {
            this.campaignToShow = _.cloneDeep(this.campaign)
            this.stageSettingsToShow = _.cloneDeep(this.stageSettings)

            this.isDirty.campaign[this.state.activeCampaignId] = false
        },

        async togglePreviewOutreach() {
            if (this.state.modals.outreachPreview.is_open === false) {
                await this.getOutreachPreview()
                this.state.modals.outreachPreview.is_open = true
            } else {
                this.state.modals.outreachPreview.is_open = false
            }
        },

        async getOutreachPreview() {
            const body = {
                campaign_type: this.campaign.type,
                outreach_config: this.stageSettingsToShow.prospect.outreach_config,
            }
            body.outreach_config.sponsorship_name = this.supercampaign?.game?.name

            this.$axios.post('/vendors/sendgrid/outreach_template', body).then(({ data }) => {
                this.outreachPreviewRaw = data.payload.output
            })
        },

        setActiveTab(tab, campaignId) {
            if (this.isActiveCampaignDirty || (campaignId && this.isDirty.supercampaign)) {
                this.state.modals.unsavedChanges.is_open = true
                this.state.modals.unsavedChanges.data = {
                    tab,
                    campaignId,
                }
                return
            }

            if (!tab || tab === '' || tab === 'settings') {
                const query = { ...this.$route.query }
                delete query.tab
                delete query.tabId
                this.$router.replace({ query })
                this.activeTab = 'settings'
            } else {
                this.$router.replace({ query: { tab, tabId: campaignId } })
                this.activeTab = tab
            }
            if (campaignId && this.state.activeCampaignId !== campaignId) {
                this.setActiveCampaignId(campaignId)
                this.stageSettings = this.stageSettingsByCampaignId[this.state.activeCampaignId]
                this.stageSettingsToShow = _.cloneDeep(this.stageSettings)
                this.ensureOutreachDefaults()
            }
        },

        setActiveCampaignId(campaignId) {
            if (campaignId) {
                const matchedCampaign = _.find(this.campaignsWithSettings, (campaign) => campaign.id === campaignId)

                if (matchedCampaign) {
                    this.state.activeCampaignId = campaignId
                }

                // If this is not provided, we assume we are initializing
            } else if (_.size(this.campaignsWithSettings) > 0) {
                this.state.activeCampaignId = _.first(this.campaignsWithSettings).id
            }

            const foundCampaign = _.find(this.campaignsById, (campaign) => campaign.id === this.state.activeCampaignId)
            this.ensureCampaignStructure(foundCampaign)
            this.campaign = foundCampaign
            this.campaignToShow = _.cloneDeep(this.campaign)
        },

        ensureCampaignStructure(campaign) {
            if (!campaign) {
                return
            }

            /* eslint-disable no-param-reassign */
            if (!campaign.payload) {
                campaign.payload = {}
            }

            if (campaign.type === 'steam-broadcast') {
                if (!campaign.payload.slots) {
                    campaign.payload.slots = {}
                }

                if (!campaign.payload.ingest_server) {
                    campaign.payload.ingest_server = {}
                }

                if (!campaign.payload.applications) {
                    campaign.payload.applications = {}
                }
            } else if (campaign.type === 'game-sponsorship') {
                if (!campaign.payload.stream_delivery) {
                    campaign.payload.stream_delivery = {}
                }
            } else if (campaign.type === 'key-distribution') {
                if (!campaign.payload.stream_delivery) {
                    campaign.payload.stream_delivery = {}
                }
            }
            /* eslint-enable no-param-reassign */
        },

        async saveStageSettings(campaignId, stage) {
            const result = await campaignSettingsAPI.saveCampaignStageSettings(
                this.$axios,
                campaignId,
                stage,
                this.stageSettingsToShow[stage],
            )

            this.stageSettingsByCampaignId[campaignId][stage] = result.value.stage_settings
        },

        async getAllSupercampaignSettings() {
            const campaignSettings = await Promise.all(
                _.map(this.campaignsWithSettings, (campaign) =>
                    campaignSettingsAPI.getCampaignSettings(this.$axios, campaign.id).then((response) => {
                        if (response.success) {
                            return {
                                id: campaign.id,
                                settings: response.value.settings,
                                stageSettings: response.value.stage_settings,
                            }
                        }
                        return undefined
                    }),
                ),
            )

            this.settingsByCampaignId = {}
            this.stageSettingsByCampaignId = {}

            _.forEach(campaignSettings, ({ id, settings, stageSettings }) => {
                this.settingsByCampaignId[id] = settings
                this.stageSettingsByCampaignId[id] = stageSettings
            })

            this.stageSettings = this.stageSettingsByCampaignId[this.state.activeCampaignId]
            this.stageSettingsToShow = _.cloneDeep(this.stageSettings)
            this.ensureOutreachDefaults()
            await this.populateKeyGroups()
        },

        async populateKeyGroups() {
            const keyGroupsResponse = await campaignKeysAPI.getSuperCampaignKeyGroups(
                this.$axios,
                this.supercampaign.id,
            )
            this.supercampaignKeyGroups = keyGroupsResponse.value.groups
        },

        async saveCampaign(campaign) {
            const result = await campaignAPI.saveCampaign(this.$axios, this.supercampaign.id, campaign)
            if (result.success) {
                this.$successHandler(`Successfully saved campaign ${campaign.title}`)
                // eslint-disable-next-line vue/no-mutating-props
                this.campaignsById[campaign.id] = result.value.campaign
            } else {
                console.error(`Error saving campaign ${campaign.title}`)
                this.$errorHandler(`Error saving campaign ${campaign.title}`)
            }
        },

        async saveSuperCampaign(supercampaign) {
            if (this.selectedGame) {
                await this.saveOrganisationGame(this.selectedGame.id)
            }
            const result = await superCampaignAPI.saveSuperCampaign(this.$axios, supercampaign)

            if (result.success) {
                this.supercampaignToShow.description = result.value.supercampaign.description
                this.supercampaignToShow.title = result.value.supercampaign.title
                this.supercampaignToShow.stage = result.value.supercampaign.stage
                this.$successHandler(`Successfully saved supercampaign ${supercampaign.title}`)
            } else {
                console.error(`Error saving supercampaign ${supercampaign.title}`)
                this.$errorHandler(`Error saving supercampaign ${supercampaign.title}`)
            }
        },

        saveCampaignSettings(campaignId, updates) {
            return campaignSettingsAPI.saveCampaignSettings(this.$axios, campaignId, updates).value
        },

        ensureOutreachDefaults() {
            if (
                this.campaign &&
                this.settings &&
                this.settings.defaults.outreach.configurationByCampaignType[this.campaign.type]
            ) {
                const defaultOutreach =
                    this.settings.defaults.outreach.configurationByCampaignType[this.campaign.type].fields
                _.forEach(this.outreachProperties, (outreachProp) => {
                    if (outreachProp.canDisable === true) {
                        const existingValue =
                            this.stageSettingsToShow.prospect.outreach_config[outreachProp.enabledFlag]
                        if (existingValue !== true && existingValue !== false) {
                            this.stageSettingsToShow.prospect.outreach_config[outreachProp.enabledFlag] =
                                defaultOutreach[outreachProp.enabledFlag]
                        }
                    }
                })
            }
        },

        onGameChange(newGame) {
            this.selectedGame = newGame
        },

        async saveOrganisationGame(id) {
            const result = await superCampaignAPI.addOrganisationGame(
                this.$axios,
                this.supercampaign.organization_id,
                id,
            )

            if (result.success) {
                // eslint-disable-next-line vue/no-mutating-props
                this.supercampaign.organization_game_id = result.value.game.id
                // eslint-disable-next-line vue/no-mutating-props
                this.supercampaign.game = result.value.game
                this.supercampaignToShow.organization_game_id = result.value.game.id
                this.supercampaignToShow.game = result.value.game
            } else {
                console.error(`Error saving organisation game for ${this.supercampaign.organization_id}`)
            }
        },

        async onConstraintsChange(campaignId, constraints) {
            const campaignSettingsToSave = this.settingsByCampaignId[campaignId]
            campaignSettingsToSave.task_constraints = constraints

            const updates = {
                task_constraints: constraints,
            }

            await this.saveCampaignSettings(campaignId, updates)
        },

        async onKeyGroupChange() {
            this.populateKeyGroups()
        },

        onDisableStage(stage, campaignType) {
            this.stageSettingsToShow[stage.stage].is_disabled = !stage.is_disabled
            this.onInputChange(campaignType)
        },
    },
}
</script>
