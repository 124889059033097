<template>
    <AddGameKeyGroupModal
        :open="addGameKeyGroupModalOpen"
        :mode="addGameKeyGroupModalOpenMode"
        :edit-group="selectedGroup"
        :organization-game-id="campaign?.organization_game_id"
        :supercampaign-id="campaign?.parent_id"
        @update:open="onAddGameKeyGroupModalClose"
        @key-group-created="onGroupAdded"
        @key-group-edited="onGroupEdited"
    ></AddGameKeyGroupModal>

    <AddGameKeysModal
        v-if="groups.length && consoles.length && marketplaces.length && regions.length"
        :groups="groups"
        :platforms="consoles"
        :marketplaces="marketplaces"
        :regions="regions"
        :supercampaign-id="campaign?.parent_id"
        :organization-game-id="campaign?.organization_game_id"
        :open="addGameKeysModalOpen"
        :default-group-id="selectedGroupId"
        @keys-added="onKeysAdded"
        @update:open="onAddGameKeysModalClose"
    ></AddGameKeysModal>

    <ModalBasic
        :title="getFromDictionary(`keys.modal.type.${modal.type}`, 'title')"
        :modal-open="modal.isOpen"
        @close-modal="modal.isOpen = false"
    >
        <div v-if="modal.type === 'remove-keys'">
            <div class="px-5 py-4">
                <div class="my-4">
                    <p class="text-sm">
                        Are you sure you want to remove
                        <span class="font-semibold">
                            {{ removedKeys.length }}
                        </span>
                        {{ $pluralize('key', removedKeys.length) }} from this group?
                    </p>

                    <FormKit id="remove-keys" type="form" :actions="false" @submit="onKeyModalSubmit"> </FormKit>
                </div>
            </div>
        </div>

        <div v-if="modal.type === 'retrieve-keys'">
            <div class="px-5 py-4">
                <div class="my-4">
                    <div v-if="modal.state === 'fetch'">
                        <p class="my-5 text-sm">
                            Are you sure you want to retrieve
                            <span class="font-semibold">
                                {{ retrievedKeys.length }}
                            </span>
                            {{ $pluralize('key', retrievedKeys.length) }} from the system? Please provide a reason
                            below.
                        </p>
                        <FormKit id="retrieve-keys" type="form" :actions="false" @submit="onKeyModalSubmit">
                            <FormKit
                                v-model="retrieval_reason"
                                type="textarea"
                                name="retrieval_reason"
                                label="Reason for retrieving keys"
                                validation="required"
                            >
                            </FormKit>
                        </FormKit>
                    </div>
                    <div v-if="modal.state === 'view'">
                        <p class="'text-sm'">Here are your claimed key codes:</p>
                        <div class="my-3 flex">
                            <ul class="my-auto w-full rounded bg-slate-100 py-3 text-center">
                                <li
                                    v-for="key in keys.filter((key) => retrievedKeys.includes(key.id))"
                                    :key="key"
                                    class="font-medium"
                                >
                                    {{ key.code }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="modal.type === 'remove-group'">
            <div class="px-5 py-4">
                <div class="my-4">
                    <p class="text-sm">Are you sure you want to remove this key group?</p>

                    <FormKit id="remove-group" type="form" :actions="false" @submit="onKeyModalSubmit"> </FormKit>
                </div>
            </div>
        </div>

        <div v-if="modal.type === 'view-keys'">
            <div class="px-5 py-4">
                <div v-if="viewedKey.retrieval_reason" class="mb-5">
                    <div class="text-xs font-semibold">Retrieval reason</div>
                    <p class="text-sm">{{ viewedKey.retrieval_reason }}</p>
                </div>

                <div class="my-3 flex">
                    <div class="my-auto w-full rounded border border-slate-200 bg-slate-100 py-3 text-center">
                        <p class="font-medium">
                            {{ viewedKey.code }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="modal.type !== 'view-keys' && !(modal.type === 'retrieve-keys' && modal.state === 'view')"
            class="border-t border-slate-200 px-5 py-4"
        >
            <div class="flex flex-wrap justify-end space-x-2">
                <button
                    class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                    @click="onModalClose(modal.type)"
                >
                    Cancel
                </button>

                <button
                    :form="modal.type"
                    :class="
                        ['remove-keys', 'remove-group'].includes(modal.type)
                            ? 'bg-red-500 text-white hover:bg-red-600'
                            : modal.type === 'retrieve-keys' && retrieval_reason === ''
                            ? 'pointer-events-none bg-slate-50 text-slate-400'
                            : 'bg-indigo-500 text-white hover:bg-indigo-600'
                    "
                    class="btn-sm"
                    :disabled="modal.type === 'retrieve-keys' && retrieval_reason === ''"
                >
                    {{ getFromDictionary(`keys.modal.type.${modal.type}`, 'actionLabel') }}
                </button>
            </div>
        </div>
    </ModalBasic>

    <div v-if="!state.isLoading">
        <div class="flex rounded border border-slate-200 p-4">
            <div class="flex flex-col space-y-2">
                <button
                    class="btn h-9 w-40 bg-indigo-500 text-white hover:bg-indigo-600"
                    @click="() => ((addGameKeyGroupModalOpenMode = 'add'), (addGameKeyGroupModalOpen = true))"
                >
                    <svg class="h-4 w-4 shrink-0 fill-current opacity-50" viewBox="0 0 16 16">
                        <path
                            d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"
                        />
                    </svg>
                    <span class="ml-2">Add Group</span>
                </button>
                <button
                    class="btn h-9 w-40"
                    :class="
                        groups.length !== 0
                            ? ' bg-indigo-500 text-white hover:bg-indigo-600'
                            : 'bg-slate-300 text-white'
                    "
                    :disabled="groups.length === 0"
                    @click="() => (addGameKeysModalOpen = true)"
                >
                    <svg class="h-4 w-4 shrink-0 fill-current opacity-50" viewBox="0 0 16 16">
                        <path
                            d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"
                        />
                    </svg>
                    <span class="ml-2">Add Keys</span>
                </button>
            </div>
            <div class="flex grow flex-col xl:flex-row">
                <div
                    v-for="group in groups"
                    :key="group.id"
                    class="mb-2 ml-4 cursor-pointer rounded bg-slate-100 px-4 py-3 hover:bg-slate-200"
                    :class="selectedGroupId === group.id ? 'bg-slate-300' : null"
                    @click="onSelectKeyGroup(group)"
                >
                    <span class="font-semibold">{{ group.title }}</span>
                    <span class="block text-sm"
                        >{{ getUnclaimedCount(group.keys) }}/{{ group.keys ? group.keys.length : 0 }} keys
                        available</span
                    >
                </div>
            </div>

            <div class="ml-5 flex flex-col space-y-2">
                <button
                    class="btn h-9 w-40"
                    :class="selectedGroupId ? 'bg-red-500 text-white hover:bg-red-600' : 'bg-slate-300 text-white'"
                    :disabled="!selectedGroupId"
                    @click="removeKeyGroupModal(selectedGroupId)"
                >
                    <svg class="h-4 w-4 shrink-0 fill-current opacity-50" viewBox="0 0 16 16">
                        <path
                            d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"
                        />
                    </svg>
                    <span class="ml-2">Remove group</span>
                </button>
                <button
                    class="btn h-9 w-40"
                    :class="
                        selectedGroupId ? 'bg-indigo-500 text-white hover:bg-indigo-600' : 'bg-slate-300 text-white'
                    "
                    :disabled="!selectedGroupId"
                    @click="() => ((addGameKeyGroupModalOpenMode = 'edit'), (addGameKeyGroupModalOpen = true))"
                >
                    <svg class="h-4 w-4 shrink-0 fill-current opacity-50" viewBox="0 0 16 16">
                        <path
                            d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"
                        />
                    </svg>
                    <span class="ml-2">Edit group</span>
                </button>
            </div>
        </div>

        <div class="mt-4 grid flex-1 grid-cols-12 gap-6">
            <SkeletonGameKeyTable v-if="state.isLoading" />
            <KeyTable
                v-if="!state.isLoading"
                :keys="selectedGroupKeys"
                :keygroupid="selectedGroupId"
                @remove-keys="removeKeyModal"
                @remove-group="removeKeyGroupModal"
                @retrieve-keys="retrieveKeyModal"
                @view-keys="viewKeyModal"
            >
            </KeyTable>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'

import ModalBasic from '@/components/ModalBasic.vue'
import KeyTable from '@/partials/tables/KeyTable.vue'
import SkeletonGameKeyTable from '@/partials/skeletons/SkeletonGameKeyTable.vue'
import AddGameKeysModal from '@/partials/modals/GameKeyModal/AddGameKeysModal.vue'
import AddGameKeyGroupModal from '@/partials/modals/GameKeyModal/AddGameKeyGroupModal.vue'
import campaignKeysAPI from '@/utils/api/api.campaign.keys'

export default {
    name: 'Key Management',

    components: {
        KeyTable,
        SkeletonGameKeyTable,
        ModalBasic,
        AddGameKeysModal,
        AddGameKeyGroupModal,
    },

    props: {
        supercampaign: {
            type: Object,
        },
        campaign: {
            type: Object,
        },
    },
    emits: ['group-change'],
    data() {
        return {
            keys: [],
            marketplaces: [],
            consoles: [],
            regions: [],
            groups: [],
            lookup: {},
            selectedGroupId: null,
            removedKeys: [],
            retrievedKeys: [],
            viewedKey: {},
            removedGroup: null,
            state: {
                isLoading: false,
            },
            modal: {
                isOpen: false,
                type: 'add-keys',
                state: '',
            },
            groupKeyValues: {
                group_id: null,
                keys: '',
            },
            groupValues: {},
            fromSchemaAddKeys: [],
            retrieval_reason: '',
            formSchemaKeyGroup: [
                {
                    $formkit: 'select',
                    name: 'type',
                    label: 'Type',
                    placeholder: 'Select type',
                    options: [
                        { label: 'Game', value: 'Game' },
                        { label: 'DLC', value: 'DLC' },
                    ],
                    validation: 'required',
                },
                {
                    $formkit: 'text',
                    name: 'title',
                    label: 'Title',
                    validation: 'required',
                },
                {
                    $formkit: 'text',
                    name: 'description',
                    label: 'Description',
                },
            ],
            addGameKeyGroupModalOpen: false,
            addGameKeyGroupModalOpenMode: '',
            addGameKeysModalOpen: false,
        }
    },
    created() {
        this.getGameKeyGroups()
    },
    computed: {
        ...mapGetters(['getFromDictionary', 'self']),
        selectedGroupKeys() {
            const selectedGroup = _.find(this.groups, (group) => group.id === this.selectedGroupId)
            if (selectedGroup) {
                return selectedGroup.keys
            }
            return []
        },
        selectedGroup() {
            return _.find(this.groups, (group) => group.id === this.selectedGroupId)
        },
    },
    methods: {
        async getGameKeyGroups() {
            this.state.isLoading = true
            const campaignKeyGroupsResponse = await campaignKeysAPI.getSuperCampaignKeyGroups(
                this.$axios,
                this.supercampaign.id,
                true,
            )
            if (campaignKeyGroupsResponse.success) {
                const campaignKeyGroupsData = campaignKeyGroupsResponse.value
                this.consoles = campaignKeyGroupsData.consoles
                this.marketplaces = campaignKeyGroupsData.marketplaces
                this.regions = campaignKeyGroupsData.regions
                this.groups = campaignKeyGroupsData.groups
                this.keys = _.reduce(
                    campaignKeyGroupsData.groups,
                    (results, group) => {
                        results.push(...group.keys)
                        return results
                    },
                    [],
                )
            }

            if (!this.selectedGroupId && this.groups.length > 0) {
                this.selectedGroupId = this.groups[0].id
            }

            this.state.isLoading = false
        },
        getUnclaimedCount(keys) {
            return _.size(_.filter(keys, (key) => !key.claimed_at))
        },
        onSelectKeyGroup(group) {
            this.selectedGroupId = group.id
        },
        openKeyModal(type) {
            this.modal.type = type
            this.modal.isOpen = true
        },
        removeKeyModal({ selected }) {
            this.modal.type = 'remove-keys'
            this.removedKeys = selected
            this.modal.isOpen = true
        },
        retrieveKeyModal({ selected }) {
            this.modal.type = 'retrieve-keys'
            this.modal.state = 'fetch'
            this.retrievedKeys = selected
            this.modal.isOpen = true
        },
        removeKeyGroupModal(groupId) {
            if (groupId) {
                this.modal.type = 'remove-group'
                this.removedGroup = groupId
                this.modal.isOpen = true
            }
        },
        viewKeyModal(key) {
            this.modal.type = 'view-keys'
            this.viewedKey = key
            this.modal.isOpen = true
        },
        async onKeyModalSubmit() {
            if (this.modal.type === 'remove-keys') {
                const deleteKeysResponse = await campaignKeysAPI.deleteGroupKeys(
                    this.$axios,
                    this.supercampaign.id,
                    this.selectedGroupId,
                    this.removedKeys,
                )

                if (deleteKeysResponse.success) {
                    this.keys = _.filter(this.keys, (key) => !_.includes(this.removedKeys, key.id))
                    _.forEach(this.groups, (group) => {
                        // eslint-disable-next-line no-param-reassign
                        group.keys = _.filter(group.keys, (key) => !_.includes(this.removedKeys, key.id))
                    })

                    this.removedKeys = []
                    this.modal.isOpen = false
                }
            }

            if (this.modal.type === 'retrieve-keys') {
                const retrieveKeysResponse = await campaignKeysAPI.retrieveKeys(
                    this.$axios,
                    this.supercampaign.id,
                    this.selectedGroupId,
                    this.retrievedKeys,
                    this.retrieval_reason,
                )

                if (retrieveKeysResponse.success) {
                    this.getGameKeyGroups()
                    this.retrieval_reason = ''
                    this.modal.state = 'view'
                }
            }

            if (this.modal.type === 'remove-group') {
                const deleteGroupResponse = await campaignKeysAPI.deleteKeyGroup(
                    this.$axios,
                    this.supercampaign.id,
                    this.removedGroup,
                )

                if (deleteGroupResponse.success) {
                    this.selectedGroupId = null
                    this.modal.isOpen = false
                    this.groups = _.filter(this.groups, (group) => group.id !== this.removedGroup)
                    this.removedGroup = null
                }
            }
            this.$emit('group-change')
        },
        onModalClose(modalType) {
            this.modal.isOpen = false

            if (modalType === 'add-keys') {
                this.groupKeyValues.group_id = null
                this.groupKeyValues.keys = ''
                this.$formkit.reset('add-keys')
            } else if (modalType === 'key-group') {
                this.groupValues = {}
                this.$formkit.reset('key-group')
            } else if (modalType === 'retrieve-keys') {
                this.retrieval_reason = ''
                this.$formkit.reset('retrieve-keys')
                this.modal.state = ''
            } else if (modalType === 'view-keys') {
                this.retrievedKeys = []
                this.viewedKey = {}
            }
        },

        onKeysAdded(updatedKeys) {
            const keyGroup = _.find(this.groups, (item) => item.id === updatedKeys[0].group_id)
            if (!keyGroup) {
                console.log('No key group found')
            }
            this.keys.push(...updatedKeys)
            keyGroup.keys.push(...updatedKeys)
            this.addGameKeysModalOpen = false
            this.$emit('group-change')
        },

        onGroupAdded(newGroup) {
            this.groups.push(newGroup)
            this.addGameKeyGroupModalOpen = false
            this.addGameKeyGroupModalOpenMode = ''
            this.$emit('group-change')
        },

        onGroupEdited(updates) {
            _.assign(this.selectedGroup, updates)
            this.addGameKeyGroupModalOpen = false
            this.addGameKeyGroupModalOpenMode = ''
            this.$emit('group-change')
        },

        onAddGameKeyGroupModalClose() {
            this.addGameKeyGroupModalOpen = false
            this.addGameKeyGroupModalOpenMode = ''
        },

        onAddGameKeysModalClose() {
            this.addGameKeysModalOpen = false
        },
    },
}
</script>
