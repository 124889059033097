<template>
    <div class="col-span-full h-fit">
        <header class="relative">
            <div class="left-0 top-0 flex h-full w-full">
                <div class="flex flex-grow">
                    <div>
                        <div
                            v-if="actions.selected.length !== 0"
                            class="flex w-8 cursor-pointer pl-1 pt-2 text-slate-500 hover:text-slate-900"
                            @click="onClearSelected"
                        >
                            <font-awesome-icon :icon="['fas', 'xmark']" class="my-auto h-4 w-4 shrink-0 fill-current" />
                        </div>
                    </div>

                    <button
                        class="btn h-8"
                        :class="
                            actions.selected.length > 0
                                ? 'whitespace-nowrap bg-indigo-500 text-white hover:bg-indigo-600'
                                : 'bg-slate-300 text-white'
                        "
                        :disabled="actions.selected.length === 0"
                        @click="onRetrieveKeys"
                    >
                        Retrieve selected keys ({{ actions.selected.length }})
                    </button>
                </div>
                <div>
                    <button
                        class="btn ml-4 h-8"
                        :class="
                            actions.selected.length > 0
                                ? 'bg-red-500 text-white hover:bg-red-600'
                                : 'bg-slate-300 text-white'
                        "
                        :disabled="actions.selected.length === 0"
                        @click="onRemoveKeys"
                    >
                        Remove selected keys from key group ({{ actions.selected.length }})
                    </button>

                    <button
                        class="btn ml-4 h-8"
                        :class="keys.length > 0 ? 'bg-red-500 text-white hover:bg-red-600' : 'bg-slate-300 text-white'"
                        :disabled="keys.length === 0"
                        @click="onRemoveAllKeys"
                    >
                        Remove all keys
                    </button>
                </div>
            </div>
        </header>

        <div class="mt-4">
            <div class="overflow-x-auto">
                <table class="w-full table-auto">
                    <thead class="rounded-sm bg-slate-50 text-xs uppercase text-slate-400">
                        <tr>
                            <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left">
                                    <label class="flex items-center">
                                        <input
                                            v-model="actions.isAllSelected"
                                            type="checkbox"
                                            class="form-checkbox"
                                            @change="onSelectAll"
                                        />
                                        <span class="ml-2 text-sm font-medium">ID</span>
                                    </label>
                                </div>
                            </th>
                            <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left font-semibold">Status</div>
                            </th>
                            <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left font-semibold">Claimed By</div>
                            </th>
                            <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left font-semibold">Claimed</div>
                            </th>
                            <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left font-semibold">Marketplace</div>
                            </th>
                            <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left font-semibold">Console</div>
                            </th>
                            <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left font-semibold">Region</div>
                            </th>
                            <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-right font-semibold">Created</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="divide-y divide-slate-200 border-b border-slate-200 text-sm">
                        <tr v-for="key in keysOnPage" :key="key.id" class="group">
                            <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5 md:w-1/3">
                                <div class="flex items-center">
                                    <div class="mr-2 w-4 shrink-0 sm:mr-3">
                                        <input
                                            v-if="key.status !== 'claimed'"
                                            v-model="actions.selected"
                                            type="checkbox"
                                            class="form-checkbox"
                                            :value="key.id"
                                        />
                                    </div>
                                    <div class="relative flex w-full items-center justify-between">
                                        <span class="text-slate-800">{{ key.id.split('-')[0] }}</span>
                                        <div class="h-[26px] w-20">
                                            <button
                                                v-if="key.status === 'claimed'"
                                                class="right-0 top-0 my-auto hidden h-[26px] cursor-pointer rounded bg-slate-100 px-2 text-xs font-semibold transition duration-200 group-hover:flex hover:bg-slate-200"
                                                @click="onViewKey(key)"
                                            >
                                                <span class="my-auto flex uppercase">
                                                    <font-awesome-icon
                                                        :icon="['fas', 'rectangle-list']"
                                                        class="my-auto mr-1 h-4 w-4"
                                                    />
                                                    <span class="my-auto leading-none">View</span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </td>

                            <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6">
                                <div class="text-left capitalize">
                                    <div
                                        class="inline-block rounded-full px-2 py-1 text-xs font-semibold"
                                        :class="{
                                            'bg-slate-100 text-slate-500': key.status === 'free',
                                            'bg-emerald-100 text-emerald-500': key.status === 'claimed',
                                        }"
                                    >
                                        <span class="uppercase">{{ key.status }}</span>
                                    </div>
                                </div>
                            </td>

                            <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6">
                                <div class="text-left">
                                    <span v-if="key.status === 'claimed' && key.user_id"> Content Creator </span>
                                    <span v-else-if="key.status === 'claimed' && key.operator_id"> Operator </span>
                                    <span v-else> --- </span>
                                </div>
                            </td>

                            <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6">
                                <div class="text-left uppercase">
                                    <span v-if="key.claimed_at">
                                        {{ getDate(key.claimed_at) }}
                                    </span>
                                    <span v-else> --- </span>
                                </div>
                            </td>

                            <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6">
                                <div class="text-left capitalize">
                                    {{ key.marketplace_id }}
                                </div>
                            </td>

                            <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6">
                                <div class="text-left uppercase">
                                    {{ key.console_id }}
                                </div>
                            </td>

                            <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6">
                                <div class="text-left capitalize">
                                    {{ key.region_id }}
                                </div>
                            </td>

                            <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6">
                                <div class="text-right uppercase">
                                    <span>{{ getDate(key.created_on) }}</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="!keys.length" class="mb-4 mt-6 text-center text-sm">No keys found</div>
                <div class="mb-2 mt-6">
                    <DiscoveryPagination :paging="paging" record-name="keys" @paging-changed="onPagingChange" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import DiscoveryPagination from '@/partials/tables/discovery/DiscoveryPagination.vue'

export default {
    name: 'KeyTable',
    components: {
        DiscoveryPagination,
    },
    props: {
        keys: {
            type: Array,
        },
        keygroupid: {
            type: String,
        },
        values: {
            search: '',
        },
    },
    emits: ['remove-keys', 'retrieve-keys', 'remove-group', 'view-keys'],
    data() {
        return {
            pager: {
                perPage: 10,
                currentPage: 1,
            },
            actions: {
                selected: [],
                isAllSelected: false,
            },
            searchText: '',
        }
    },
    computed: {
        ...mapGetters(['self']),
        keysOnPage() {
            const { currentPage, perPage } = this.pager
            const startIndex = (currentPage - 1) * perPage
            return this.keys.slice(startIndex, currentPage * perPage)
        },
        paginationTotal() {
            const total = Math.ceil(this.keys.length / this.pager.perPage)
            if (total === 0) {
                return 1
            }
            return total
        },
        paging() {
            return {
                perPage: this.pager.perPage,
                currentPage: this.pager.currentPage,
                totalRecords: this.keys.length,
                totalPages: this.paginationTotal,
            }
        },
    },
    watch: {
        keys() {
            // If any new keys are brought into this component through any means
            // unselected all keys
            this.actions.selected = []
            this.actions.isAllSelected = false
            this.pager.currentPage = 1
            this.pager.totalRecords = this.keys.length
            this.pager.totalPages = this.paginationTotal
        },
    },
    methods: {
        getDate(date) {
            return moment(date).format('MM/DD/YYYY')
        },
        onClearSelected() {
            this.actions.selected = []
            this.actions.isAllSelected = false
        },
        onSelectAll() {
            if (this.actions.isAllSelected) {
                this.actions.selected = this.getAllSelectable()
            } else {
                this.actions.selected = []
            }
        },
        getAllSelectable() {
            return _.map(
                _.filter(this.keys, (key) => key.status !== 'claimed'),
                'id',
            )
        },
        onRemoveAllKeys() {
            this.$emit('remove-keys', {
                selected: this.getAllSelectable(),
            })
        },
        onRemoveKeys() {
            this.$emit('remove-keys', {
                selected: this.actions.selected,
            })
        },
        onRemoveKeyGroup() {
            this.$emit('remove-group', this.keygroupid)
        },
        onRetrieveKeys() {
            this.$emit('retrieve-keys', {
                selected: this.actions.selected,
            })
            this.onClearSelected()
        },
        onViewKey(key) {
            this.$emit('view-keys', key)
        },
        onPagingChange(updatedPaging) {
            _.assign(this.pager, updatedPaging)
        },
    },
}
</script>
